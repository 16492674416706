import React from 'react';

import Checkbox from "../../../../components/UI/Checkbox/Checkbox";
import TableEntityAbstract from "../../../../components/table-entity/table-entity.abstract";
import Pagination from "../../../../components/pagination/pagination";
import Button from "../../../../components/UI/Button/Button";

import '../../../../components/table-entity/table-intity.scss';

class OwnerEmployeesTable extends TableEntityAbstract {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            checkboxes: []
        }
    }

    handleCheckedItem(id) {
        const {checkboxes} = this.state;

        if (checkboxes.includes(id)) {
            this.setState({checkboxes: checkboxes.filter(item => item !== id)});
        } else {
            checkboxes.push(id);
            this.setState({checkboxes: checkboxes});
        }
    }


    setAll(status) {
        const checkboxes = !status ? [] : this.props.data.filter(item => !item.sendInvite).map(item => item.id).flat();

        this.setState({checkboxes})
    }

    checkCheckedAll() {
        return this.props.data.filter(item => !item.sendInvite).length === this.state.checkboxes.length && this.state.checkboxes.length > 0;
    }

    render() {
        const checkedAll = this.checkCheckedAll(),
            startSlice = (this.state.page - 1) * this.perPage,
            endSlice = startSlice + this.perPage,
            {checkboxes} = this.state,
            isShowCheckboxAll = this.props.data.filter(item => !item.sendInvite).length > 0;

        return (
            this.props.data.length > 0 &&
            <>
                <div className="table-entity head-border">
                    <input
                        name="participants"
                        value={`${JSON.stringify(checkboxes)}`}
                        type="hidden"/>

                    <table>
                        <thead>
                        <tr>
                            <th className="table-entity__first-column">
                                {isShowCheckboxAll && this.props.allowInvite &&
                                    <Checkbox checked={checkedAll} onChange={() => this.setAll(!checkedAll)}/>
                                }
                            </th>
                            <th className="text-center w-1">ФИО</th>
                            <th className="text-center w-1">Адрес</th>
                            <th className="text-center w-1">Роль</th>
                            <th className="text-center w-1">Уровень</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.data.slice(startSlice, endSlice).map((item, index) => {
                                index = startSlice + index;
                                return (
                                    <tr key={`tre-${item.id}`}>
                                        <td className="text-center">
                                            {
                                                !item.sendInvite ?
                                                    this.props.allowInvite &&
                                                        <Checkbox checked={!!checkboxes.includes(item.id)}
                                                        name={`userIds[${item.userId}]`}
                                                        onChange={() => this.handleCheckedItem(item.id)}
                                                        value={checkboxes.includes(item.id) ? 1 : 0}/>
                                                :
                                                <i className="icon-check text-4xl"/>

                                            }
                                        </td>
                                        <td>{item.personName}</td>
                                        <td className="text-left">
                                            {item.address}
                                        </td>
                                        <td className="text-left">
                                            {item.group}
                                        </td>
                                        <td className="text-left">
                                            {item.level}
                                        </td>
                                    </tr>
                                )}
                            )
                        }
                        </tbody>
                    </table>
                </div>

                <Pagination totalPages={this.props.data.length / this.perPage} invertColor={true}
                            onNavigate={(page) => this.changePage(page)} className="mt-6"/>

                <Button type="submit"
                        className="btn btn-blue mt-6"
                        disabled={this.state.checkboxes.filter(item => item).length === 0}>
                    Отправить</Button>
            </>
        )
    }
}

export default OwnerEmployeesTable;
