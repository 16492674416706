import React from 'react';
import Layout from '../../../layouts/authorized';
import {NavLink} from "react-router-dom";
import Select from "../../../components/UI/Select/Select";
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import PromoList from "../../../components/promo-list/promo-list";
import Spinner from "../../../components/spinner/spinner";

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searching: true
        }
    }


    componentDidMount() {
        axios.get(
            `${apiHost}/promotions/get/list`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({items: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({searching: false});
        });
    }

    render() {

        return (
            <>
                <h2 className="page-title">Текущие акции</h2>

                {   this.state.searching
                    ? <Spinner active={this.state.searching}/>
                    :
                        this.state.items.length === 0
                            ? <p>Нет доступных акций</p>
                            : <PromoList items={this.state.items}/>
                }

            </>

        );
    }
}

export default Page;
