import React, {Fragment} from 'react';

import PageAcademyList from './education-academy-list';
import PageAcademyTest from './education-academy-test';
import PageAcademyVideo from './education-academy-video';

import NotFound from '../../404/404'

class Page extends React.Component {

    render() {
        const path = this.props.location.pathname.split('/');

        let page;
        const currentPage = path[4];

        switch (currentPage) {
            case undefined:
                page = <PageAcademyList {...this.props}/>;
                break;
            case 'test':
                page = <PageAcademyTest {...this.props}/>;
                break;
            case 'video':
                page = <PageAcademyVideo {...this.props}/>;
                break;
            default:
                return (<NotFound/>)
        }

        return (<>{page}</>);
    }
}


export default Page;
