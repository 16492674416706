import React from 'react';
import Modal from './modal';
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import {apiHost} from "../../common/config";
import store from "../../store/store";
import Button from "../UI/Button/Button";
import {NavLink} from "react-router-dom";
import Pagination from "../pagination/pagination";



class ProcessingModal extends React.Component {

    constructor(props) {
        super(props);
    }

    notCloseModal() {
        return false;
    }


    render() {

        return (
            <Modal isOpen={this.props.showModalOpen}
                   onClose={() => this.notCloseModal()}
                   hideCloseButton={true}
                   hideCloseIcon={true}
                   boxStyle={{maxWidth: '38rem'}}>
                <div className="text-center">
                    <p className="text-center">Пожалуйста, подождите, ваш запрос обрабатывается!</p>
                    <div className="w-full flex justify-center">
                        <object type="image/svg+xml" data="/assets/resources/Infinity-2s-200px.svg">
                            <img src="/assets/resources/Infinity-2s-200px.svg" alt="Обработка ..."/>
                        </object>
                    </div>
                </div>
            </Modal>
        );
    }
};

export default ProcessingModal;
