import React from "react";

const NotionRateTableComponent = (props) => {
    const stopCalculateDate = new Date(props.stopCalculateDate);
    const currentDate = new Date();

    return (
        <div className="bg-orange-lightest border-l-4 border-orange text-orange-dark p-4 mt-6 mb-10 w-full" role="alert">
            <p>
                {
                    stopCalculateDate > currentDate
                        ? 'Внимание, ниже в таблице выводится предварительный рейтиг,' +
                        ' основанный на данных всех внесенных продаж. Окончательные результаты будут опубликованный ' +
                        ' после ' + stopCalculateDate.toLocaleDateString('ru-RU') + '.'
                        : 'Внимание, ниже в таблице приведены окончательные результаты конкурса.'
                }
            </p>
        </div>
    )
};

export default NotionRateTableComponent;
