import React from 'react';
import Layout from '../../../layouts/authorized';
import SalesUploadForm from './sales-upload-form';
import Modal from "../../../components/modal/modal";
import Button from "../../../components/UI/Button/Button";

class Page extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Layout>
                <h2 className="page-title">Продажи</h2>
                <SalesUploadForm role="consultant" />
            </Layout>
        );
    }
}



export default Page;