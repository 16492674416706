import React from 'react';
import Checkbox from "../UI/Checkbox/Checkbox";
import './table-intity.scss';
import TableEntityAbstract from "./table-entity.abstract";
import Pagination from "../pagination/pagination";
import queryString from "query-string";
import Button from "../UI/Button/Button";
import Select from "../UI/Select/Select";

class TableEntityOwner extends TableEntityAbstract {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        };

    }

    componentDidMount() {
        if (this.props.currentPromo.isJoinedPromo) {
            const checkboxes = [];
            this.props.data.map((item, index) => checkboxes[index] = !item.isJoined);
            this.setState({checkboxes}, this.checkCheckedAll);
        } else {
            super.componentDidMount();
        }

    }

    handleCheckedItem(index, userIds) {
        let checkedSubLocal = [];
        const {checkboxes, checkedSub} = this.state;

        checkboxes[index] = !checkboxes[index];

        if (checkboxes[index]) {
            checkedSub.push(...userIds);
            checkedSubLocal = checkedSub;
        } else {
            checkedSubLocal =  checkedSub.filter(x => !userIds.includes(x));
        }

        this.setState({checkboxes, checkedSub: checkedSubLocal});
    }

    setAll(status) {
        const checkboxes = [];
        const checkedSub = !status ? [] : this.props.data.map((item) => item.userIds).flat();
        console.log(checkedSub);

        this.props.data.map((item, index) => checkboxes[index] = status);
        this.setState({checkboxes, checkedSub})
    }

    render() {
        let checkedAll = this.checkCheckedAll(),
            startSlice = (this.state.page - 1) * this.perPage,
            endSlice = startSlice + this.perPage;


        return (
            this.props.data.length > 0 &&
            <>
                {
                    !this.props.currentPromo.isJoinedPromo &&
                    (
                        this.props.currentPromo.withCondition ?
                            <>
                                <p className="mb-6">
                                    Для того чтобы принять участие в Акции выберите формат и оптики</p>
                                <div className="conditionFormat">
                                    <Select
                                        name="conditionFormat"
                                        label="Формат участия"
                                        placeholder="Выберите..."
                                        options={this.props.currentPromo.conditions}
                                        defaultValue={this.props.currentPromo.conditions.slice(0, 1)}
                                    />
                                </div>
                            </>
                            : <p className="mb-6">
                                Для того чтобы принять участие в Акции выберите оптики</p>
                    )
                }

                {
                    this.props.currentPromo.isJoinedPromo &&
                    (
                        this.props.currentPromo.joinedCondition ?
                            <>
                                <p className="title-semibold title-semibold__black">
                                    В Акции участвуют выбранные оптики</p>
                                <p className="mb-6">Формат акции {this.props.currentPromo.joinedCondition}</p>
                            </>
                            : <p className="title-semibold title-semibold__black mb-6">
                                В Акции участвуют выбранные оптики:</p>
                    )
                }


                <div className="table-entity head-border">
                    <input
                        name="userOptics"
                        value={JSON.stringify(this.state.checkedSub)}
                        type="hidden"/>

                    <table>
                        <thead>
                        <tr>
                            <th className="table-entity__first-column">
                                <Checkbox checked={checkedAll} onChange={() => this.setAll(!checkedAll)}/>
                            </th>
                            <th className="text-center w-1">Оптики</th>
                            <th className="text-center w-1">Участвует</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.data.slice(startSlice, endSlice).map((item, index) => {
                                    index = startSlice + index;
                                    return (
                                        <tr key={`treo-${index}`}>
                                            <td className="text-center">
                                                <Checkbox checked={!!this.state.checkboxes[index]}
                                                          name={`optics[${item.opticId}]`}
                                                          onChange={() => this.handleCheckedItem(index, item.userIds)}
                                                          value={item.userOwnerId}/>
                                            </td>
                                            <td>{index + 1}. {item.opticName}</td>
                                            <td className="text-center">
                                                {item.isJoined && <i className="icon-check text-4xl"/>}
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>

                </div>
                <Pagination totalPages={this.props.data.length / this.perPage} invertColor={true}
                            onNavigate={(page) => this.changePage(page)} className="mt-6"/>

                {
                    !this.props.currentPromo.isJoinedPromo ?
                        <>
                            <Button type="submit"
                                    value="optics"
                                    className="btn btn-blue mt-6 mr-2"
                                    disabled={this.state.checkboxes.filter(item => item).length === 0}>
                                Принять участие c оптиками</Button>
                            <Button type="submit"
                                    value="noOptics"
                                    className="btn btn-blue mt-6"
                                    disabled={this.state.checkboxes.filter(item => item).length !== 0}>
                                Принять участие без оптик</Button>
                        </>
                        :
                        <>
                            <Button type="submit"
                                    className="btn btn-blue btn-thin mt-6 mr-2"
                                    disabled={this.state.checkboxes.filter(item => item).length === 0}>
                                Подключить</Button>
                            <Button
                                    className="btn btn-grey btn-thin mt-6"
                                    disabled={this.state.checkboxes.filter(item => item).length === 0}
                                    onClick={this.props.releaseHandler}
                                    >
                                Отключить</Button>

                        </>
                }
            </>
        )
    }
}

export default TableEntityOwner;
