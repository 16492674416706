import React from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import {apiHost} from '../../../common/config';
import Layout from '../../../layouts/authorized';
import store from "../../../store/store";
import FileList from "../../../components/file-list/file-list";
import ProfileProgressBar from "../../../components/profile-progress-bar/profile-progress-bar";
import ResizeDetector from "react-resize-detector";
import ProfileProgressCircle from "../../../components/profile-progress-circle/profile-progress-circle";
import ProfileProgressCup from "../../../components/profile-progress-cup/profile-progress-cup";
import ProfileProgressLine from "../../../components/profile-progress-line/profile-progress-line";
import PromoList from "../../../components/promo-list/promo-list";
import Modal from '../../../components/modal/modal';
import KeyPointsComponent from "./key-points.component";

import './home.scss';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userData: {
                ROLE: {
                    code: '',
                    label: ''
                },
                EMAIL: '',
                NAME: '',
                PERSONAL_PHONE: '',
                LEGAL_ENTITY: '',
                PHOTO: '',
                UF_USER_ECP_LEVEL: 0,
            },
            news: [],
            page: 1,
            files: [],
            promotions: [],
            courses: [],
            educationPoints: {
                maxPoints: 9999,
                points: 0,
            },
            salePoints: {
                maxPoints: 9999,
                points: 0,
            },
            allPoints: {
                maxPoints: 0,
                points: 0,
            },
            keySalePoints: 0,
            cups: [],
            certificates: {
                fast_start: {
                    status: false
                },
                varalux: {
                    status: false
                },
                essilor_essensial: {
                    status: false
                },
                professional: {
                    status: false
                },
                expert: {
                    status: false
                },
                essilor_professional_doc: {
                    status: false
                },
                essilor_professional_con: {
                    status: false
                }
            },
            certModalSelected: {},
            certModalOpen: false

        }
    }

    componentDidMount()
    {
        axios.get(
            `${apiHost}/user/get/info`,
            {withCredentials: true}
        )
        .then(res => {
            this.setState({userData: res.data});
        })
        .catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        axios.get(
            `${apiHost}/news/get/list/${this.state.page}`,
            {withCredentials: true}
        )
        .then(res => {
            this.setState({news: this.state.news.concat(res.data)});
        })
        .catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        axios.get(
            `${apiHost}/library/getLastFiles`, {
                params: {
                    count: 6
                },
                withCredentials: true
            }
        ).then(res => {
            this.setState({files: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        axios.get(
            `${apiHost}/promotions/get/list`,
            {withCredentials: true}
        )
        .then(res => {
            this.setState({promotions: res.data});
        })
        .catch(error => {

        });

        axios.get(
            `${apiHost}/education/get/tests`,
            {withCredentials: true}
        )
            .then(res => {
                this.setState({courses: res.data});
            })
            .catch(error => {

            });

        this.getEducationPoints();
        this.getSalePoints();
        this.getCups();
        this.getCertificates();
        this.getKeySalePoints();
    }

    getEducationPoints(){
        axios.get(
            `${apiHost}/user/get/points/education`,
            {withCredentials: true}
        )
        .then(res => {
            const { allPoints } = this.state;
            allPoints.maxPoints += res.data.maxPoints;
            allPoints.points += res.data.points;

            this.setState({educationPoints: res.data, allPoints});
        })
        .catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getSalePoints(){
        axios.get(
            `${apiHost}/user/get/points/sale`,
            {withCredentials: true}
        )
        .then(res => {
            const { allPoints } = this.state;
            allPoints.maxPoints += res.data.maxPoints;
            allPoints.points += res.data.points;

            this.setState({salePoints: res.data, allPoints});
        })
        .catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }


    getKeySalePoints(){
        axios.get(
            `${apiHost}/user/get/points/keySale`,
            {withCredentials: true}
        )
            .then(res => {
                this.setState({keySalePoints: res.data});
            })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });
    }


    getCups(){
        axios.get(
            `${apiHost}/user/get/cups`,
            {withCredentials: true}
        )
            .then(res => {
                this.setState({cups: res.data});
            })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });
    }


    getCertificates() {
        axios.get(
            `${apiHost}/user/get/certificates`,
            {withCredentials: true}
        )
        .then(res => {
            this.setState({certificates: res.data})
        })
        .catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }


    openCert(code) {
        const { certificates } = this.state;

        if (certificates[code]['status']) {
            this.setState({
                certModalOpen: true,
                certModalSelected: certificates[code]
            });
        }
    }

    closeModal() {
        this.setState({
            certModalOpen: false
        });
    }

    downloadCert(code) {
        window.open(`${apiHost}/user/get/certificate/${code}`);
    }

    render() {
        const {courses, salePoints, educationPoints, allPoints, cups, certificates, keySalePoints} = this.state,
            showProgressBlock = this.state.userData.ROLE.isClinic,
            saleBarCaption = this.state.userData.ROLE.code === 'DOCTOR_USERS' ? 'Рекомендации' : 'Продажи',
            showKeySaleProgressBlock = this.state.userData.UF_USER_ECP_LEVEL == '3';

        return (
            <Layout>
                <h2 className="page-title">Личный кабинет</h2>

                <div className="my-6 clearfix w-full">
                    <div className="md:float-left md:w-1/4 text-center mb-6">
                        {this.state.userData.USER_PHOTO ? (
                                <img className="md:w-4/5" src={this.state.userData.USER_PHOTO}/>
                            ) : (
                                <img className="md:w-4/5" src='/assets/resources/no-ava.jpg'/>
                            )
                        }
                    </div>
                    <div className="md:float-right md:w-3/4">
                        <div className="title-semibold title-semibold__black">{this.state.userData.NAME}</div>
                        <div className="mt-3">
                            <ul className="flex flex-col md:flex-row">
                                {   this.state.userData.ROLE.code !== 'OWNER_USERS'
                                        && this.state.userData.ROLE.code !== 'HELPER_USERS'
                                    ?
                                        <li className="flex-initial mr-5 py-1 md:py-0">
                                            <span className="icon-user text-essilor-light-blue pr-1"/>
                                            <span className="pr-4">
                                                {this.state.userData.ROLE.label}
                                            </span>
                                        </li>

                                    : null
                                }
                                <li className="flex-initial mr-5 py-1 md:py-0">
                                    <span className="icon-mail text-essilor-light-blue pr-1"/>
                                    {this.state.userData.EMAIL}
                                </li>
                                <li className="flex-initial py-1 md:py-0">
                                    <span className="icon-phone text-essilor-light-blue pr-1"/>
                                    {this.state.userData.PERSONAL_PHONE}
                                </li>
                            </ul>
                        </div>
                    </div>


                    {
                        showProgressBlock &&
                        <ResizeDetector handleWidth>
                            {
                                ({ width }) => {
                                    return (
                                        <div className={`border-t-2 border-dashed mt-6 ${width < 1000 ? `float-right w-full` : `float-right w-3/4`}`}>

                                            <div className="mt-5">
                                                <ProfileProgressBar
                                                    currentPosition={this.state.userData.UF_USER_ECP_LEVEL}
                                                    width={width}
                                                    roleCode={this.state.userData.ROLE.code}
                                                />

                                                <div className="mt-10">
                                                    <div className="md:mx-0 md:float-left md:w-1/4 md:pr-6 max-w-full mb-6 mx-auto">
                                                        <ProfileProgressCircle
                                                            value={allPoints.points}
                                                            valueMax={allPoints.maxPoints}
                                                        />
                                                    </div>

                                                    { cups &&
                                                        <ul className="md:float-right md:w-3/4 w-full block">
                                                            {
                                                                cups.map(cup => (
                                                                    <li className="float-left mb-2">
                                                                        <ProfileProgressCup tip={cup.tip} inactive={!cup.status}/>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>

                                                    }

                                                    <div className="md:float-right md:w-3/4 md:clear-none mt-5 w-full clear-both">
                                                        <ProfileProgressLine valueMax={educationPoints.maxPoints} value={educationPoints.points} title="Обучение"/>
                                                    </div>
                                                    <div className="md:float-right w-full md:w-3/4">
                                                        <ProfileProgressLine valueMax={salePoints.maxPoints} value={salePoints.points} title={saleBarCaption} color="green"/>
                                                    </div>
                                                    {
                                                        showKeySaleProgressBlock &&
                                                        <div className="md:float-right w-full md:w-3/4">
                                                            <ProfileProgressLine
                                                                valueMax={16}
                                                                value={keySalePoints}
                                                                title={`Ключевые ${saleBarCaption.toLowerCase()}`}
                                                                color="green"
                                                                counting="линз"
                                                            />
                                                            <KeyPointsComponent/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="mt-5 clear-both">
                                                <div className="title-semibold title-semibold__black">Сертификаты</div>
                                                <ul className="cert-wrapp justify-between flex flex-wrap">
                                                    { this.state.userData.ROLE.code !== 'DOCTOR_USERS' &&
                                                        <>
                                                            <li className={`${!certificates.fast_start.status && 'inactive'}`}>
                                                                <img src="/assets/resources/diplomas/diploma-1.png"
                                                                     srcSet="/assets/resources/diplomas/diploma-1.png, /assets/resources/diplomas/diploma-1@2x.png 2x"
                                                                     onClick={() => this.openCert('fast_start')}/>
                                                            </li>
                                                            <li className={`${!certificates.essilor_professional_con.status && 'inactive'}`}>
                                                                <img src="/assets/resources/diplomas/diploma-es-pr.png"
                                                                    srcSet="/assets/resources/diplomas/diploma-es-pr.png, /assets/resources/diplomas/diploma-es-pr@2x.png 2x"
                                                                    onClick={() => this.openCert('essilor_professional_con')}/>
                                                            </li>
                                                        </>
                                                    }
                                                    { this.state.userData.ROLE.code !== 'CONSULTANT_USERS' &&
                                                        <>
                                                            <li className={`${!certificates.varalux.status && 'inactive'}`}>
                                                                <img src="/assets/resources/diplomas/diploma-5.png"
                                                                     srcSet="/assets/resources/diplomas/diploma-5.png, /assets/resources/diplomas/diploma-5@2x.png 2x"
                                                                     onClick={() => this.openCert('varalux')}/>
                                                            </li>
                                                            <li className={`${!certificates.essilor_professional_doc.status && 'inactive'}`}>
                                                                <img src="/assets/resources/diplomas/diploma-es-pr.png"
                                                                    srcSet="/assets/resources/diplomas/diploma-es-pr.png, /assets/resources/diplomas/diploma-es-pr@2x.png 2x"
                                                                    onClick={() => this.openCert('essilor_professional_doc')}/>
                                                            </li>
                                                        </>
                                                    }
                                                    <li className={`${!certificates.professional.status && 'inactive'}`}>
                                                        <img src="/assets/resources/diplomas/diploma-3.png"
                                                             srcSet="/assets/resources/diplomas/diploma-3.png, /assets/resources/diplomas/diploma-3@2x.png 2x"
                                                             onClick={() => this.openCert('professional')}/>
                                                    </li>
                                                    <li className={`${!certificates.expert.status && 'inactive'}`}>
                                                        <img src="/assets/resources/diplomas/diploma-4.png"
                                                             srcSet="/assets/resources/diplomas/diploma-4.png, /assets/resources/diplomas/diploma-4@2x.png 2x"
                                                             onClick={() => this.openCert('expert')}/>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        </ResizeDetector>
                    }


                </div>

                {
                    (this.state.userData.ROLE.code !== 'CONSULTANT_USERS' && this.state.userData.ROLE.code !== 'DOCTOR_USERS')
                        && this.state.promotions.length > 0 &&
                            <div className="mt-6 w-full">
                                <div className="white-box__header">Акции</div>
                                <PromoList items={this.state.promotions}/>
                            </div>
                }

                <div className="flex justify-between w-full flex-col md:flex-row">
                    <div className="white-box mt-6 md:w-1/2 w-full p-0 md:mr-3">
                        <div className="white-box__header">Новые файлы в Библиотеке</div>
                        <div className="white-box__content">
                            <FileList files={this.state.files}/>
                        </div>
                    </div>

                    <div className="items-result relative mt-6 md:w-1/2 w-full  p-0 md:ml-3 courses">
                        <div className="courses-box__header">Пройти обучение</div>
                        {
                            _.isEmpty(courses) ? null :
                                courses.map(course => (
                                    <a className="items-result__item items-result__item-full relative courses__item cursor-pointer w-full home-items"
                                       key={`course-${course.id}`}
                                       href={`/lk/education/courses/${course.code}`}>
                                        <div className="items-result__item-title justify-between text-white uppercase font-bold">
                                            {course.name}
                                        </div>
                                        { course.status === 'start' &&
                                        <span
                                            className="absolute top-0 right-0 bg-blue-100 text-white courses__item-status courses__item-status--start">
                                            Начать
                                        </span>
                                        }

                                        { course.status === 'continue' &&
                                        <span
                                            className="absolute top-0 right-0 bg-blue-100 text-white courses__item-status courses__item-status--continue">
                                            Продолжить
                                        </span>
                                        }

                                        { course.status === 'finish' &&
                                        <span
                                            className="absolute top-0 right-0 bg-blue-100 text-white courses__item-status courses__item-status--finish">
                                            Завершено
                                        </span>
                                        }
                                    </a>
                                ))
                        }
                    </div>
                </div>

                <div className="white-box mt-6 clearfix w-full p-0">
                    <div className="white-box__header">Новости</div>
                    <div className="white-box__content">
                        <div className="news-short">
                            {
                                this.state.news.slice(0,4).map((item, index) => (
                                    <div className="news-short__item" key={`news-${item.id}`} >

                                        <NavLink to={`/lk/news/${item.id}`}>
                                            <img className="" src={item.img}/>
                                        </NavLink>
                                        <small>{item.date}</small>
                                        <NavLink to={`/lk/news/${item.id}`} className="news-short__item-title block">
                                            {item.name}
                                        </NavLink>
                                        <NavLink to={`/lk/news/${item.id}`} className="news-short__item-text">
                                            {item.text}
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {
                    (this.state.userData.ROLE.code === 'CONSULTANT_USERS' || this.state.userData.ROLE.code === 'DOCTOR_USERS')
                        && this.state.promotions.length > 0 &&
                            <div className="mt-6 w-full">
                                <div className="white-box__header">Акции</div>
                                <PromoList items={this.state.promotions}/>
                            </div>
                }

                { !this.state.certModalOpen ||
                    <Modal isOpen={this.state.certModalOpen}
                           hideCloseButton="true"
                           onClose={() => this.closeModal()}>
                        {
                            this.state.certModalSelected &&
                                <>
                                    <div className={`relative modal-cert-wrapp cert-${this.state.certModalSelected.code.toLowerCase()}`}>
                                        <img src={this.state.certModalSelected.img} />
                                        <div className="modal-cert-name">
                                            {this.state.userData.NAME}
                                        </div>

                                        <center>
                                            <a className="btn btn-thin btn-blue cursor-pointer"
                                               onClick={() => this.downloadCert(this.state.certModalSelected.code)}>
                                                Скачать сертификат
                                            </a>
                                        </center>
                                    </div>
                                </>
                        }

                    </Modal>
                }
            </Layout>
        );
    }
}



export default Page;
