
const initState = {
    userName: localStorage.getItem('userName'),
    userLogin: localStorage.getItem('userLogin'),
    userGroups: localStorage.getItem('userGroups'),
    userPhoto: localStorage.getItem('userPhoto'),
    isLogged: localStorage.getItem('isLogged') === 'true',
}


export default (state = initState, action) => {

    Object.keys(initState).map((key) => {
        if (initState[key] === 'undefined') {
            initState[key] = '';
        }
    })


    switch (action.type) {
        case 'signIn':
            localStorage.setItem('userName', action.userName);
            localStorage.setItem('userLogin', action.userLogin);
            localStorage.setItem('userGroups', action.userGroups);
            localStorage.setItem('isLogged', action.isLogged);
            localStorage.setItem('userPhoto', action.userPhoto);

            return {
                ...state,
                userName: action.userName,
                userLogin: action.userLogin,
                userGroups: action.userGroups,
                isLogged: action.isLogged,
                userPhoto: action.userPhoto
            };
        case 'logout':
            localStorage.removeItem('userName');
            localStorage.removeItem('userLogin');
            localStorage.removeItem('isLogged');
            localStorage.removeItem('userPhoto');
            localStorage.removeItem('userGroups');
            localStorage.removeItem('stat-sf');
            localStorage.removeItem('stat-marketing');

            return {
                ...state, userName: '', userLogin: '', isLogged: '', userPhoto: '', userGroups: ''
            };
        case 'refreshUserInfo':
            localStorage.setItem('userName', action.userName);
            localStorage.setItem('userPhoto', action.userPhoto);

            return {
                ...state,
                userName: action.userName,
                userPhoto: action.userPhoto
            };
        default:
            return {...state}
    }
}
