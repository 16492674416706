import React from 'react';
import axios from "axios";

import {apiHost} from '../../../../common/config';
import store from "../../../../store/store";
import Select from "../../../../components/UI/Select/Select";

class FilterRateTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            addresses: [
                {value: '', label: 'Все оптики'},
            ],
            subordinates: [
                {value: '', label: 'Все сотрудники'},
            ],
            entities: [
                {value: '', label: 'Все партнёры'},
            ],
            groups: [
                {value: '', label: 'Все роли'},
                {value: 'DOCTOR_USERS', label: 'Врач'},
                {value: 'CONSULTANT_USERS', label: 'Консультант'}
            ],
            filter: {
                legalEntity: '',
                group: '',
                address: '',
                subordinate: '',
            }
        }

    }

    componentDidMount() {
        this.getEntities();
    }

    getAddresses() {
        const {filter} = this.state;

        if (!filter.legalEntity) {
            return false;
        }

        axios.get(
            `${apiHost}/entity/get/addresses/by/entity/${filter.legalEntity}`,
            {withCredentials: true,}
        ).then(res => {
            const { addresses } = this.state;
            this.setState({
                addresses: [...[{value: '', label: 'Все оптики'}], ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getSubordinates() {
        const {filter} = this.state;

        if (!filter.address) {
            return false;
        }

        axios.get(
            `${apiHost}/user/get/subordinates/by/address/${filter.address}`,
            {withCredentials: true,}
        ).then(res => {
            const { subordinates } = this.state;
            this.setState({
                subordinates: [...[{value: '', label: 'Все сотрудники'}], ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getEntities() {
        axios.get(
            `${apiHost}/entity/getlist`,
            {withCredentials: true,}
        ).then(res => {
            const { entities } = this.state;
            this.setState({
                entities: [...entities, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    handleChangeGroup(selectedOption) {
        const {filter} = this.state;
        filter.group = selectedOption.value;
        this.setState({filter});
    }

    handleChangeAddress(selectedOption) {
        const {filter} = this.state;
        filter.address = selectedOption.value;
        this.setState({filter});

        this.checkIfNeedClearInputs('address');
        this.getSubordinates();
    }

    handleChangeSubordinate(selectedOption) {
        const {filter} = this.state;
        filter.subordinate = selectedOption.value;
        this.setState({filter});
    }

    handleChangeEntity(selectedOption) {
        const {filter} = this.state;
        filter.legalEntity = selectedOption.value;
        this.setState({filter});

        this.checkIfNeedClearInputs('legalEntity');
        this.getAddresses();
    }

    checkIfNeedClearInputs = (field) => {
        const {filter} = this.state;

        if (field == 'legalEntity') {
            filter.address = '';
            filter.subordinate = '';
            filter.group = '';
        }

        if (field == 'address') {
            filter.subordinate = '';
            filter.group = '';
        }

        if (field == 'subordinate') {
        }

        this.setState({filter});
    }

    handleSearch() {
        const {filter} = this.state;
        this.props.handleSearch(filter);
    }

    render() {
        const {filter} = this.state;

        return (
            <>
                <div className="w-full md:flex md:justify-between md:w-3/4">
                    { this.props.fields.includes('entities') &&
                        <div className="md:w-6/12 md:mr-5">
                            <Select
                                name="entities"
                                label="Выберите партнёра"
                                placeholder="Все партнёры"
                                options={this.state.entities}
                                className="w-full"
                                onChange={(selectedOption) => this.handleChangeEntity(selectedOption)}
                                isDisabled={this.props.searching}
                            />
                        </div>
                    }
                    <div className="md:w-6/12 md:ml-5">
                        {this.props.fields.includes('addresses') &&
                            <Select
                                name="addresses"
                                label="Выберите адрес"
                                placeholder="Все оптики"
                                options={this.state.addresses}
                                className="w-full"
                                onChange={(selectedOption) => this.handleChangeAddress(selectedOption)}
                                isDisabled={this.props.searching || filter.legalEntity == ''}
                                value={this.state.addresses.filter((item) => item.value === filter.address)}
                            />
                        }
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        {this.props.fields.includes('users') &&
                            <Select
                                name="users"
                                label="Выберите сотрудника"
                                placeholder="Все сотрудники"
                                options={this.state.subordinates}
                                className="w-full"
                                onChange={(selectedOption) => this.handleChangeSubordinate(selectedOption)}
                                isDisabled={this.props.searching || filter.address == ''}
                                value={this.state.subordinates.filter((item) => item.value === filter.subordinate)}
                            />
                        }
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        {this.props.fields.includes('groups') &&
                            <Select
                                name="groups"
                                label="Выберите роль"
                                placeholder="Все роли"
                                options={this.state.groups}
                                className="w-full"
                                onChange={(selectedOption) => this.handleChangeGroup(selectedOption)}
                                isDisabled={this.props.searching}
                                value={this.state.groups.filter((item) => item.value === filter.group)}
                            />
                        }
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.props.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={() => this.handleSearch()}>Найти</button>

                        </div>
                    </div>

                    <div className="md:w-6/12 md:ml-5"></div>
                </div>
            </>
        );
    }
}

export default FilterRateTableComponent;
