import React from "react";

const EntityConditionComponent = (props) => {
    const titles = {doctor: 'врач', consultant: 'консультант'};

    return (
        <div className="bg-orange-lightest border-l-4 border-orange text-orange-dark p-4 mt-6 w-full" role="alert">
            <p>В данном конкурсе учтаствуют роли: {props.roles.map(role => titles[role]).join(', ')}.</p>
        </div>
    )
};

export default EntityConditionComponent;