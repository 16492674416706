import React from 'react';
import Form from '../../components/forms/forms';
import {NavLink} from 'react-router-dom';
import Icon from '../../icons/';
import Input from '../../components/UI/Input/Input';
import store from '../../store/store';
import {apiHost} from '../../common/config';
import Layout from '../../layouts/unauthorized';
import Button from "../../components/UI/Button/Button";
import FeedbackModalExt from "../../components/feedback-modal/feedback-modal-ext";

class Page extends Form {

    requestUrl = `${apiHost}/user/signin`;

    validationRules = {
        email: {require: true, title: 'Электронная почта', email: true},
        password: {require: true, title: 'Пароль', minLength: 6},
    }

    submitRequestCalllback = (res) => {
        localStorage.setItem('shouldBeShowModal', 'yes');

        store.dispatch({
            type: 'signIn',
            userName: res.data.USER_NAME,
            userLogin: res.data.USER_LOGIN,
            userGroups: res.data.USER_GROUPS,
            userPhoto: res.data.USER_PHOTO,
            isLogged: true
        });
    };

    render() {
        return (
            <Layout>
                <div
                    className="flex min-h-screen flex-wrap md:flex-no-wrap md:flex-row flex-col-reverse main-page">
                    <div className="lg:w-2/5 md:w-1/2  p-6 flex flex-col mx-auto">
                        <div className="px-4 text-center flex flex-col justify-center  items-center h-full">

                            <div className="text-center">
                                <h1 className="black">Авторизация</h1>
                                <p className="text-essilor-gray">Добро пожаловать, введите ваши данные</p>
                            </div>

                            <div className="text-center text-red-500 h-10 mt-6">{this.state.responseError}</div>

                            <div className="w-4/5 text-left mx-auto">
                                <form onSubmit={this.submitHandler.bind(this)} method="post">
                                    <Input label="Электронная почта" placeholder="Введите ваш e-mail" name="email"
                                           errors={this.state.errors}
                                           onChange={this.removeError}/>
                                    <Input label="Пароль" type="password" name="password"
                                           placeholder="Введите ваш пароль"
                                           errors={this.state.errors}
                                           onChange={this.removeError}/>
                                    {
                                        this.state.status === 'ready'
                                            ?
                                                <Button type="submit" className="btn-blue uppercase my-8 block w-full">
                                                    Войти
                                                </Button>
                                            :
                                                <div className="w-full flex justify-center">
                                                    <object width="60" type="image/svg+xml" data="/assets/resources/Infinity-2s-200px.svg">
                                                        <img src="/assets/resources/Infinity-2s-200px.svg" alt="Обработка..." />
                                                    </object>
                                                </div>
                                    }
                                </form>

                                <p className="text-center flex justify-between">
                                    <small className="opacity-50 hover:opacity-100">
                                        <NavLink to="/password-reset">Забыл пароль</NavLink>
                                    </small>
                                </p>

                                <p className="text-essilor-gray mt-5">
                                    Для регистрации на портале обратитесь к вашему менеджеру Essilor
                                </p>

                                <NavLink to="/">
                                    <Icon name="logo" fill="#3A84D0" className="mx-auto max-w-8/9 mt-10"/>
                                </NavLink>

                                <FeedbackModalExt/>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-3/5 md:w-1/2 background-main"/>
                </div>
            </Layout>
        );
    }
}


export default Page;
