import React from "react";

const Spinner = (props) => {
    return (
        props.active
            ?
            <div className="w-full md:w-3/4 flex justify-center">
                <object type="image/svg+xml" data="/assets/resources/Infinity-2s-200px.svg">
                    <img src="/assets/resources/Infinity-2s-200px.svg" alt="Поиск ..."/>
                </object>
            </div>
        : null
    )
}

export default Spinner;