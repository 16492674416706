import React from 'react';

import Checkbox from "../../../../components/UI/Checkbox/Checkbox";
import TableEntityAbstract from "../../../../components/table-entity/table-entity.abstract";
import Pagination from "../../../../components/pagination/pagination";
import Button from "../../../../components/UI/Button/Button";

import '../../../../components/table-entity/table-intity.scss';

class EntityTableComponent extends TableEntityAbstract {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            checkboxes: [],
            checkedDoc: [],
            checkedCons: [],

        }
    }

    handleCheckedItem(index, id) {
        const {checkedDoc, checkedCons} = this.state;

        if (checkedDoc.includes(id) && checkedCons.includes(id)) {
            this.setState({
                checkedDoc: checkedDoc.filter(item => item !== id),
                checkedCons: checkedCons.filter(item => item !== id)
            });
        } else {
            const checkedDocLocal = checkedDoc.filter(item => item !== id);
            const checkedConsLocal = checkedDoc.filter(item => item !== id);
            checkedDocLocal.push(id);
            checkedConsLocal.push(id);

            this.setState({
                checkedDoc: checkedDocLocal,
                checkedCons: checkedConsLocal
            });
        }
    }

    handleCheckedItemDoc(index, id) {
        const {checkedDoc} = this.state;

        if (checkedDoc.includes(id)) {
            this.setState({checkedDoc: checkedDoc.filter(item => item !== id)});
        } else {
            checkedDoc.push(id);
            this.setState({checkedDoc: checkedDoc});
        }
    }

    handleCheckedItemConsultant(index, id) {
        const {checkedCons} = this.state;

        if (checkedCons.includes(id)) {
            this.setState({checkedCons: checkedCons.filter(item => item !== id)});
        } else {
            checkedCons.push(id);
            this.setState({checkedCons: checkedCons});
        }
    }

    prepareInputValue() {
        const {checkedDoc, checkedCons} = this.state;
        const value = {
          doctors: checkedDoc,
          consultants: checkedCons
        };

        return value;
    }

    checkCheckedAll() {
        const {checkedDoc, checkedCons} = this.state,
            checkboxesSum = (checkedDoc.length +  checkedCons.length);

        return this.props.data.filter(item => !item.status).length === checkboxesSum/2
            && checkboxesSum  > 0;
    }

    setAll(status) {
        const ids = this.props.data.filter(item => !item.status).map(item => item.id);

        if (status) {
            this.setState({checkedCons: ids, checkedDoc: ids});
        } else {
            this.setState({checkedCons: [], checkedDoc: []});
        }
    }

    render() {
        const checkedAll = this.checkCheckedAll(),
            startSlice = (this.state.page - 1) * this.perPage,
            endSlice = startSlice + this.perPage,
            {checkedDoc, checkedCons} = this.state,
            isShowCheckboxAll = this.props.data.filter(item => !item.status).length > 0;

        return (
            this.props.data.length > 0 &&
            <>
                <div className="table-entity head-border">
                    <input
                        name="participants"
                        value={`${JSON.stringify(this.prepareInputValue())}`}
                        type="hidden"/>

                    <table>
                        <thead>
                            <tr>
                                <th rowSpan="2" className="table-entity__first-column">
                                    {isShowCheckboxAll && this.props.allowInvite &&
                                        <Checkbox checked={checkedAll} onChange={() => this.setAll(!checkedAll)}/>
                                    }
                                </th>
                                <th rowSpan="2" className="text-center w-1">Партнёры</th>
                                <th colSpan="2" className="text-center w-1">Выбор роли</th>
                                <th rowSpan="2" className="text-center w-1">Приглашение<br/>отправлено</th>
                                <th rowSpan="2" className="text-center w-1">Участвует</th>
                            </tr>
                            <tr>
                                <th className="text-center w-1">Врач</th>
                                <th className="text-center w-1">Консультант</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.data.slice(startSlice, endSlice).map((item, index) => {
                                index = startSlice + index;
                                    return (
                                        <tr key={`tre-${item.userId}`}>
                                            <td className="text-center">
                                                {
                                                    !item.status && this.props.allowInvite &&
                                                    <Checkbox checked={!!(checkedDoc.includes(item.id) && checkedCons.includes(item.id))}
                                                              name={`userIds[${item.userId}]`}
                                                              onChange={() => this.handleCheckedItem(index, item.id)}
                                                              value={(checkedDoc.includes(item.id) &&  checkedCons.includes(item.id)) ? 1 : 0}/>
                                                }
                                            </td>
                                            <td>{index + 1}. {item.name}</td>
                                            <td className="text-center">
                                                {
                                                    !item.status && this.props.allowInvite
                                                        ?
                                                            <Checkbox checked={!!this.state.checkedDoc.includes(item.id)}
                                                                      name={`userIds[${item.userId}]`}
                                                                      onChange={() => this.handleCheckedItemDoc(index, item.id)}
                                                                      value={this.state.checkedDoc.includes(item.id) ? 1 : 0}/>
                                                        :
                                                            item.roles.includes('doctor') &&
                                                                <i className="icon-check text-4xl"/>
                                                }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    !item.status && this.props.allowInvite
                                                        ?
                                                        <Checkbox checked={!!this.state.checkedCons.includes(item.id)}
                                                                  name={`userIds[${item.userId}]`}
                                                                  onChange={() => this.handleCheckedItemConsultant(index, item.id)}
                                                                  value={this.state.checkedCons.includes(item.id) ? 1 : 0}/>
                                                        :
                                                        item.roles.includes('consultant') &&
                                                        <i className="icon-check text-4xl"/>
                                                }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    item.status && <i className="icon-check text-4xl"/>
                                                }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    item.status === 'join' && <i className="icon-check text-4xl"/>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>
                </div>

                <Pagination totalPages={this.props.data.length / this.perPage} invertColor={true}
                            onNavigate={(page) => this.changePage(page)} className="mt-6"/>

                <Button type="submit"
                        className="btn btn-blue mt-6"
                        disabled={checkedDoc.filter(item => item).length === 0
                            && checkedCons.filter(item => item).length === 0}>
                    Отправить</Button>
            </>
        )
    }
}

export default EntityTableComponent;
