import React from 'react';
import axios from 'axios';

import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Contests from '../../../components/contest/list';
import Spinner from '../../../components/spinner/spinner';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searching: true
        }
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/contest`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({items: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({searching: false});
        });
    }

    render() {

        return (
            <>
                <h2 className="page-title">SalesUp</h2>

                {   this.state.searching
                        ? <Spinner active={this.state.searching}/>
                        : this.state.items.length === 0
                            ? <p>Нет доступных активностей, следите за обновлениями.</p>
                            : <Contests items={this.state.items}/>
                }

            </>

        );
    }
}

export default Page;
