import React from 'react';
import Form from '../../components/forms/forms';
import {NavLink} from 'react-router-dom';
import Icon from '../../icons/';
import {apiHost} from '../../common/config';
import queryString from 'query-string';
import axios from 'axios';
import Layout from '../../layouts/unauthorized';
import FeedbackModalExt from "../../components/feedback-modal/feedback-modal-ext";


class Page extends Form {

    constructor(props) {
        super(props);

        this.state = {
            responseError: '',
            responseSuccess: false,
            responseSuccessMessage: '',
        };
    }

    componentDidMount() {
        const {hash} = queryString.parse(this.props.location.search);

        if (hash) {
            axios.get(
                `${apiHost}/user/password/reset/by/${hash}`
            ).then(res => {
                this.setState({responseSuccess: true, responseSuccessMessage: res.message});
            })
                .catch(error => {
                    this.setState({responseError: error.response.data.error.message});
                });
        } else {
            this.setState({responseError: 'Ссылка не действительна'});
        }
    }

    render() {
        return (
            <Layout>
                <div
                    className="flex min-h-screen flex-wrap md:flex-no-wrap md:flex-row flex-col-reverse main-page">
                    <div className="lg:w-2/5 md:w-1/2  p-6 flex flex-col mx-auto">
                        <div className="px-4 text-center flex flex-col justify-center  items-center h-full">

                            <div className="text-center">
                                <h1 className="black">Восстановление пароля</h1>
                            </div>

                            {
                                this.state.responseError
                                    ?
                                        <div className="text-center text-red-500 h-10 mt-6">
                                            {this.state.responseError}
                                        </div>
                                    :
                                        <div className="text-center h-10 mt-6">
                                            {this.state.responseSuccessMessage}
                                        </div>
                            }



                            <div className="w-4/5 text-left mx-auto mt-10">
                                <p className="text-center flex justify-between">
                                    <small className="opacity-50 hover:opacity-100">
                                        <NavLink to="/signin">Войти в личный кабинет</NavLink>
                                    </small>
                                    <small className="opacity-50 hover:opacity-100">
                                        <NavLink to="/signup">Регистрация</NavLink>
                                    </small>
                                </p>

                                <NavLink to="/">
                                    <Icon name="logo" fill="#3A84D0" className="mx-auto max-w-8/9 mt-10"/>
                                </NavLink>

                                <FeedbackModalExt/>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-3/5 md:w-1/2 background-main"/>
                </div>
            </Layout>
        );
    }

    renderIfSuccess() {
        return (
            <div className="text-center text-white my-6">
                <p>На ваш E-mail было отправлено письмо с новыми данными для входа в систему.
                    Если письмо не пришло, пожалуйста, проверьте папку "Спам".</p>
            </div>
        )
    }
}

export default Page;
