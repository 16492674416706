import React, { useState, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import "./DatePicker.scss";

import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';


class CustomDatePickerRange extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: new Date(),
            endDate: new Date()
        }
    }

    handleChangeStart = date => {
        const {endDate} = this.state;

        this.setState({
            startDate: date
        });
        this.change(date, endDate);
    };

    handleChangeEnd = date => {
        const {startDate} = this.state;

        this.setState({
            endDate: date
        });
        this.change(startDate, date);
    };

    change(startDate, endDate) {
        this.props.onChange(startDate, endDate);
    }

    render() {
        const ref = React.createRef();
        const {startDate, endDate} = this.state;
        const CustomInput = forwardRef(({ value, onClick }, _ref) => (
            <div className='datepicker'>
                <div className={`datepicker__custom-input ${this.props.isDisabled && 'datepicker__custom-input__disabled'}`}
                     onClick={onClick}
                >
                    {value}
                    <i className="datepicker__icon icon-calendar"></i>
                </div>
                <input type="hidden" name="date" value={this.state.startDate} />
            </div>
        ));

        return (

            <div className="datepicker-range">
                <label className="datepicker__label">
                    Выберите период
                </label>
                <div className="flex">
                    <div className="mr-1 w-full md:w-1/2">
                        <DatePicker
                            selected={startDate}
                            onChange={this.handleChangeStart}
                            selectsStart
                            customInput={<CustomInput ref={ref}/>}
                            startDate={startDate}
                            endDate={endDate}
                            locale={ru}
                            dateFormat="dd/MM/yyyy"
                            readOnly={this.props.isDisabled}
                        />
                    </div>
                    <div className="ml-1 w-full md:w-1/2">
                        <DatePicker
                            selected={endDate}
                            onChange={this.handleChangeEnd}
                            selectsEnd
                            customInput={<CustomInput ref={ref}/>}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            locale={ru}
                            dateFormat="dd/MM/yyyy"
                            readOnly={this.props.isDisabled}
                        />
                    </div>
                </div>
            </div>


        )
    }
};

export default CustomDatePickerRange;