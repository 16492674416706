import React from 'react';

import RateTableComponent from './rate.table.component';

class ExpandedRateTableComponent extends RateTableComponent {

    constructor(props) {
        super(props);

        this.state = {
            headers: [
                {code: 'place', caption: 'Место в<br/>рейтинге', type: 'number'},
                {code: 'personName', caption: 'ФИО', type: 'text'},
                {code: 'entity', caption: 'Партнёр', type: 'text'},
                {code: 'address', caption: 'Адрес', type: 'text'},
                {code: 'entityStatusEe', caption: 'Статус Партнёра', type: 'text'},
                {code: 'addressStatusEe', caption: 'Статус ТТ', type: 'text'},
                {code: 'count', caption: 'Итого шт', type: 'number'},
                {code: 'point', caption: 'Баллы', type: 'number'},
            ],
            tableData: [],
            filter: {
                limit: 20,
                page: 1,
            },
            searching: true,
            allRecords: 0,
        }

    }
}

export default ExpandedRateTableComponent;
