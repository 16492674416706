import React from "react";
import {useState, useEffect} from 'react';

import Modal from '../../../../components/modal/modal';
import Button from "../../../../components/UI/Button/Button";



const IntroductionComponent = (props) => {
    const [stateModalOpen, setStateModalOpen] = useState(false);

    const handleClickLink = () => {
        setStateModalOpen(true);
    };


    return (
        <>
            <button onClick={handleClickLink}>
                Инструкция
            </button>
            <Modal isOpen={stateModalOpen} onClose={() => setStateModalOpen(false)} hideCloseButton={true}
                   boxStyle={{maxWidth: '75rem'}}>
                <div className="text-center">
                    <h2 className="title-semibold title-semibold__black">Описание механики раздела!</h2>
                    <div className="text-left mt-20" dangerouslySetInnerHTML={{__html: props.text}} />
                    <Button className="btn btn-blue btn-narrow w-56 mt-20" style={{fontWeight: 'normal'}}
                            onClick={() => setStateModalOpen(false)}>Понятно</Button>
                </div>
            </Modal>
        </>
    )
};

export default IntroductionComponent;
