import React from "react";
import {NavLink} from "react-router-dom";

const List = (props) => {
    if (props.items.length === 0) {
        return '';
    }

    return (

        <div className="items-result w-full">
            {
                props.items.map(item =>
                    (
                        <div className="items-result__item item-white box-shadow" key={`item-${item.id}`}>
                            <img src={item.image}/>
                            <div className="flex justify-between">
                                {
                                    item.isNew && !item.isFinished &&
                                    <div className="label label__blue">Новый конкурс</div>
                                }
                                {
                                    item.isFinished &&
                                        <div className="label label__purple">Завершён</div>
                                }
                                {
                                    !item.isFinished && !item.isNew &&
                                        <div className="label"></div>
                                }
                                <div className="flex items-center item-white__time">
                                    <span className="pr-3 text-right">
                                        {item.dateText}
                                    </span>
                                </div>
                            </div>
                            <div className="content flex-col flex">
                                <h2 className="title">{item.name}</h2>
                                <div className="description">
                                    {item.text}
                                </div>
                            </div>
                            <div
                                className="flex pt-4 text-left  w-full mt-auto bottom-content">
                                <NavLink to={`/lk/contest/${item.id}`}>
                                    Подробнее</NavLink>
                            </div>
                        </div>
                    )
                )
            }

        </div>
    );
}

export default List;
