import React from "react";
import {useState, useEffect} from 'react';

import axios from "axios";

import {apiHost} from "../../../common/config";
import Modal from '../../../components/modal/modal';
import Button from "../../../components/UI/Button/Button";
import ProfileProgressLine from "../../../components/profile-progress-line/profile-progress-line";

import './key-points.scss';


const KeyPointsComponent = () => {

    const [stateModalOpen, setStateModalOpen] = useState(false);
    const [stateStatus, setStateStatus] = useState('processing');
    const [stateSales, setStateSales] = useState({'variluxComfortMaxLenses' : [], 'crizalEyezenLenses': []});
    const countLenses = (lenses) => {
        const quantity = lenses.filter(
                (sale) => !['transitionsGen8', 'transitionsXTRActive', 'transitionsXTRActivePolarized'].includes(sale.technologyValue)
            )
            .map((sale) => parseInt(sale.count))
            .reduce((prev, next) => (prev + next), 0);
        const quantityWithTechnology =  lenses.filter(
                (sale) => sale.technologyValue !== ""
                    && ['transitionsGen8', 'transitionsXTRActive', 'transitionsXTRActivePolarized'].includes(sale.technologyValue)
            )
            .map((sale) => parseInt(sale.count))
            .reduce((prev, next) => (prev + next), 0);
        const summary = (quantity > 4 ? 4 : quantity) + (quantityWithTechnology > 8 ? 8 : quantityWithTechnology);

        return summary > 8 ? 8 : summary;
    };

    const handleClickLink = () => {
        setStateModalOpen(true);

        if (stateStatus === 'ready') {
            return false;
        }

        const getVariluxComfortMaxLenses = axios.get(
            `${apiHost}/user/get/keysales`,
            {
                withCredentials: true,
                params: {
                    informationDesign: 'variluxComfortMax'
                }
            }
        );

        const getCrizalEyezenLenses = axios.get(
            `${apiHost}/user/get/keysales`,
            {
                withCredentials: true,
                params: {
                    informationDesign: 'crizalEyezen'
                }
            }
        );

        axios.all([getVariluxComfortMaxLenses, getCrizalEyezenLenses])
            .then(axios.spread((...responses) => {
                setStateSales({
                    ...stateSales,
                    'variluxComfortMaxLenses': responses[0].data,
                    'crizalEyezenLenses': responses[1].data
                });
            }))
            .finally(() => {
                setStateStatus('ready');
            });
    };


    return (
        <>
            <a className='key-points-link' onClick={handleClickLink}>
                Показать детализацию
            </a>
            <Modal isOpen={stateModalOpen} onClose={() => setStateModalOpen(false)} hideCloseButton={true}
                   boxStyle={{maxWidth: '75rem'}}>
                <div className="text-center">
                    <h2 className="title-semibold title-semibold__black">Детализация по ключевым продажам:</h2>

                    {   stateStatus !== 'ready' ?
                            <div className="text-center mt-20">
                                <p className="text-center">Пожалуйста, подождите, ваш запрос обрабатывается!</p>
                                <div className="w-full flex justify-center">
                                    <object type="image/svg+xml" data="/assets/resources/Infinity-2s-200px.svg">
                                        <img src="/assets/resources/Infinity-2s-200px.svg" alt="Обработка ..."/>
                                    </object>
                                </div>
                            </div>
                        :
                            <div className='key-points mt-20'>
                                <div className='key-points-item'>
                                    <div className='title-semibold title-semibold__black key-points-item-title'>
                                        Varilux Comfort Max<span>8</span><span>Линз</span>
                                    </div>
                                    <ul className='mt-5 mb-10'>
                                        <li>4 пары Varilux Comfort Max</li>
                                        <li>2 пары из них с технологией Transitions</li>
                                    </ul>
                                    <div className='md:w-1/2 w-full'>
                                        <h4 className='title-semibold title-semibold__black mt-0 mb-2'>
                                            Прогресс:
                                        </h4>
                                        <ProfileProgressLine
                                            valueMax={8}
                                            value={countLenses(stateSales.variluxComfortMaxLenses)}
                                            counting="линз"
                                        />
                                    </div>
                                    { stateSales.variluxComfortMaxLenses.length > 0 &&
                                        <>
                                            <h4 className='title-semibold title-semibold__black mt-10 mb-2'>
                                                Детализация по продажам:
                                            </h4>
                                            <div className="custom-table">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Склад/RX</th>
                                                        <th>Группа</th>
                                                        <th>Дизайн</th>
                                                        <th>Наименование</th>
                                                        <th>Технология</th>
                                                        <th>Покрытие</th>
                                                        <th>Количество</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        stateSales.variluxComfortMaxLenses.map((sale) => {
                                                            return (
                                                                <tr>
                                                                    <td>{sale.source}</td>
                                                                    <td>{sale.group}</td>
                                                                    <td>{sale.design}</td>
                                                                    <td>{sale.name}</td>
                                                                    <td>{sale.technology}</td>
                                                                    <td>{sale.covering}</td>
                                                                    <td>{sale.count}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className='key-points-item mt-20'>
                                    <div className='title-semibold title-semibold__black key-points-item-title'>
                                        Crizal Eyezen<span>8</span><span>Линз</span>
                                    </div>
                                    <ul className='mt-5 mb-10'>
                                        <li>4 пары Crizal Eyezen</li>
                                        <li>2 пары из них с технологией Transitions</li>
                                    </ul>
                                    <div className='md:w-1/2 w-full'>
                                        <h4 className='title-semibold title-semibold__black mt-0 mb-2'>
                                            Прогресс:
                                        </h4>
                                        <ProfileProgressLine
                                            valueMax={8}
                                            value={countLenses(stateSales.crizalEyezenLenses)}
                                            counting="линз"
                                        />
                                    </div>
                                    { stateSales.crizalEyezenLenses.length > 0 &&
                                        <>
                                            <h4 className='title-semibold title-semibold__black mt-10 mb-2'>
                                                Детализация по продажам:
                                            </h4>
                                            <div className="custom-table">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Склад/RX</th>
                                                        <th>Группа</th>
                                                        <th>Дизайн</th>
                                                        <th>Наименование</th>
                                                        <th>Технология</th>
                                                        <th>Покрытие</th>
                                                        <th>Количество</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        stateSales.crizalEyezenLenses.map((sale) => {
                                                            return (
                                                                <tr>
                                                                    <td>{sale.source}</td>
                                                                    <td>{sale.group}</td>
                                                                    <td>{sale.design}</td>
                                                                    <td>{sale.name}</td>
                                                                    <td>{sale.technology}</td>
                                                                    <td>{sale.covering}</td>
                                                                    <td>{sale.count}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                    }

                    <Button className="btn btn-blue btn-narrow w-56 mt-20" style={{fontWeight: 'normal'}}
                            onClick={() => setStateModalOpen(false)}>Понятно</Button>
                </div>
            </Modal>
        </>
    )
};

export default KeyPointsComponent;
