import React from 'react';

import Layout from '../../../../layouts/authorized';
import {apiHost} from '../../../../common/config';
import axios from "axios";
import store from "../../../../store/store";
import CustomTable from '../../../../components/table/table.2.0';
import FilterRateTableComponent from './filter.rate.table.component';
import NotionRateTableComponent from './notion.rate.table.component';

class RateTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            headers: [
                {code: 'place', caption: 'Место в<br/>рейтинге', type: 'number'},
                {code: 'personName', caption: 'ФИО', type: 'text'},
                {code: 'count', caption: 'Итого шт', type: 'number'},
                {code: 'point', caption: 'Баллы', type: 'number'},
            ],
            tableData: [],
            filter: {
                limit: props.limit,
                page: 1,
            },
            searching: true,
            allRecords: 0,
        }

    }

    componentDidMount() {
        const {filter} = this.state;
        this.getTableData(filter);
    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/contest/${this.props.contestId}/rating`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });

    }


    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = (params) => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true,
            filter: {...filter, ...params}
        });

        this.getTableData({...filter, ...params});
    }

    render() {
        const {filter} = this.state;

        return (
            <>
                {this.props.filterFields.length > 0 &&
                    <FilterRateTableComponent
                        handleSearch={this.handleSearch}
                        searching={this.state.searching}
                        fields={this.props.filterFields}
                    />
                }
                <NotionRateTableComponent stopCalculateDate={this.props.stopCalculateDate}/>
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={filter.limit}
                    currentPage={filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/contest/${this.props.contestId}/rating?filter=${JSON.stringify(filter)}`}
                    exportLimit={1000}
                />

            </>
        );
    }
}

export default RateTableComponent;
