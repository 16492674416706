import React from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';

import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import IntroductionComponent from './components/instruction.component';
import EntityConditionComponent from './components/entity.condition.component';
import InviteForm from './components/wrapp.invite.form.component';
import {default as RateTableComponent} from './components/expanded.rate.table.component';


import './contest.scss';

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/');
        this.id = path[3];
        this.section = path[4];

        this.state = {
            section: 'stat',
            item: {},
            allowInvite: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(
            `${apiHost}/contest/${this.id}`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({item: res.data,  allowInvite: !res.data.isFinished});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    changeSection(newSection) {
        const {section} = this.state;

        if (section !== newSection) {
            this.setState({section: newSection});
        }
    }

    renderStatSection() {
        return (
            <RateTableComponent
                contestId={this.id}
                stopCalculateDate={this.state.item.stopCalculateDate}
                filterFields={['entities', 'addresses', 'users']}
            />
        )
    }

    renderDescriptionSection(item) {
        return (
            <>
                <div className="blue-light-box w-full p-0">
                    <div className="white-box__header">Описание</div>
                    <div className="white-box__content">
                        <img className="md:float-left mr-0 mb-4 md:mr-4 mb-2 w-full md:w-1/2 lg:w-4/12" src={item.img}/>
                        <div className="flex justify-between mb-4 items-baseline">
                            <h2 className="text-xl uppercase">{item.name}</h2>
                        </div>

                        <div className="text-justify" dangerouslySetInnerHTML={{__html: item.text}}/>
                    </div>
                </div>
                <EntityConditionComponent roles={item.condition}/>
                <InviteForm type={'employees'} contestId={this.id} allowInvite={this.state.allowInvite}/>
            </>
        );
    }

    render() {
        const {item} = this.state;

        return (
            Object.keys(item).length > 0
                ?
                    <>
                        <h2 className="page-title page-title--breadcrumbs">{item.name}</h2>
                        <ul className="breadcrumbs">
                            <li><NavLink to="/lk/contest">SalesUp</NavLink></li>
                        </ul>

                        <ul className="submenu">
                            <li className={this.state.section === 'stat' && 'active'}>
                                <button onClick={() => this.changeSection('stat')}>Рейтинг</button>
                            </li>
                            <li className={this.state.section === 'description' && 'active'}>
                                <button onClick={() => this.changeSection('description')}>Выдать доступ</button>
                            </li>
                            <li className='red'>
                                <IntroductionComponent text={item.instruction}/>
                            </li>
                        </ul>

                        {
                            this.state.section === 'stat'
                                ? this.renderStatSection()
                                : this.renderDescriptionSection(item)
                        }
                    </>
                :
                    <p>SalesUp не найден</p>
        );
    }
}

export default Page;
