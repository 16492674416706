import React from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';

import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Layout from '../../../layouts/authorized';
import Select from '../../../components/UI/Select/Select';
import CustomTable from '../../../components/table/table.2.0';


class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tableData: [],
            errors: {},
            responseError: '',
            headers: [
                {code: 'source', caption: 'Источник (RX/Склад)', type: 'text'},
                {code: 'group', caption: 'Группа', type: 'text'},
                {code: 'design', caption: 'Дизайн', type: 'text'},
                {code: 'name', caption: 'Наименование', type: 'text'},
                {code: 'cover', caption: 'Покрытиe', type: 'text'},
                {code: 'technology', caption: 'Технология', type: 'text'},
                {code: 'point', caption: 'Баллы', type: 'text'},
            ],
            designs: [
                {value: '', label: 'Все'},
            ],
            covers: [
                {value: '', label: 'Все'},
            ],
            technologies: [
                {value: '', label: 'Все'},
            ],
            sources: [
                {value: '', label: 'Все'},
            ],
            filter: {
                design: '',
                cover: '',
                technology: '',
                source: '',
                limit: 10,
                page: 1,
            },
            searching: true,
            allRecords: 0,
        }
    }

    componentDidMount = () => {
        const {filter} = this.state;
        this.getTableData(filter);
        this.getOptions();
    };

    getOptions() {
        axios.get(
            `${apiHost}/product/options`,
            {withCredentials: true,}
        ).then(res => {
            const { designs, covers, technologies, sources } = this.state;
            this.setState({designs: [...designs, ...res.data.designs],
                covers: [...covers, ...res.data.covers],
                technologies: [...technologies, ...res.data.technologies],
                sources: [...sources, ...res.data.sources]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/product`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });
    }

    handleChangeDesign(selectedOption) {
        const {filter} = this.state;
        filter.design = selectedOption.value;
        this.setState({filter});
    }

    handleChangeCover(selectedOption) {
        const {filter} = this.state;
        filter.cover = selectedOption.value;
        this.setState({filter});
    }

    handleChangeTechnology(selectedOption) {
        const {filter} = this.state;
        filter.technology = selectedOption.value;
        this.setState({filter});
    }

    handleChangeSource(selectedOption) {
        const {filter} = this.state;
        filter.source = selectedOption.value;
        this.setState({filter});
    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    render() {

        return (
            <Layout>

                <h2 className="page-title">Информация по продукту</h2>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="design"
                            label="Дизайн"
                            placeholder="Все"
                            options={this.state.designs}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeDesign(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="cover"
                            label="Покрытие"
                            placeholder="Все"
                            options={this.state.covers}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeCover(selectedOption)}
                        />
                    </div>
                </div>
                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="technology"
                            label="Технология"
                            placeholder="Все"
                            options={this.state.technologies}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeTechnology(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="source"
                            label="Источник(RX/Склад)"
                            placeholder="Все"
                            options={this.state.sources}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeSource(selectedOption)}
                        />
                    </div>
                </div>
                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.state.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={this.handleSearch}>Найти</button>

                        </div>
                    </div>

                    <div className="md:w-6/12 md:ml-5"></div>
                </div>

                {  this.state.tableData.length > 0 &&
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/products-information?filter=${JSON.stringify(this.state.filter)}`}
                />
                }
            </Layout>
        );
    }
}



export default Page;
