import React from 'react';
import Checkbox from "../UI/Checkbox/Checkbox";
import './table-intity.scss';
import TableEntityAbstract from "./table-entity.abstract";
import Pagination from "../pagination/pagination";
import Button from "../UI/Button/Button";

class TableEntitySaleForce extends TableEntityAbstract {

    constructor(props) {
        super(props);
    }

    handleCheckedItem(index, userIds) {
        let checkedSubLocal = [];
        const {checkboxes, checkedSub} = this.state;

        checkboxes[index] = !checkboxes[index];

        if (checkboxes[index]) {
            checkedSub.push(...userIds);
            checkedSubLocal = checkedSub;
        } else {
            checkedSubLocal =  checkedSub.filter(x => !userIds.includes(x));
        }

        this.setState({checkboxes, checkedSub: checkedSubLocal});
    }


    setAll(status) {
        const checkboxes = [];
        const checkedSub = !status ? [] : this.props.data.map((item) => item.userIds).flat();
        console.log(checkedSub);

        this.props.data.map((item, index) => checkboxes[index] = status);
        this.setState({checkboxes, checkedSub})
    }

    render() {
        const checkedAll = this.checkCheckedAll(),
            startSlice = (this.state.page - 1) * this.perPage,
            endSlice = startSlice + this.perPage;

        return (
            this.props.data.length > 0 &&
            <>
                <div className="table-entity head-border">
                    <input
                        name="userIdsString"
                        value={`${JSON.stringify(this.state.checkedSub)}`}
                        type="hidden"/>

                    <table>
                        <thead>
                        <tr>
                            <th className="table-entity__first-column">
                                <Checkbox checked={checkedAll} onChange={() => this.setAll(!checkedAll)}/>
                            </th>
                            <th className="text-center w-1">Партнёры</th>
                            <th className="text-center w-1">Приглашение<br/>отправлено</th>
                            <th className="text-center w-1">Участвует</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.data.slice(startSlice, endSlice).map((item, index) => {
                                index = startSlice + index;
                                    return (
                                        <tr key={`tre-${index}`}>
                                            <td className="text-center">
                                                <Checkbox checked={!!this.state.checkboxes[index]}
                                                          name={`userIds[${item.userId}]`}
                                                          onChange={() => this.handleCheckedItem(index, item.userIds)}
                                                          value={this.state.checkboxes[index] ? 1 : 0}/>
                                            </td>
                                            <td>{index + 1}. {item.entityName}</td>
                                            <td className="text-center">
                                                {item.isSent && <i className="icon-check text-4xl"/>}
                                            </td>
                                            <td className="text-center">
                                                {item.isJoined && <i className="icon-check text-4xl"/>}
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>
                </div>

                <Pagination totalPages={this.props.data.length / this.perPage} invertColor={true}
                            onNavigate={(page) => this.changePage(page)} className="mt-6"/>

                <Button type="submit"
                        className="btn btn-blue mt-6"
                        disabled={this.state.checkboxes.filter(item => item).length === 0}>
                    Отправить</Button>
            </>
        )
    }
}

export default TableEntitySaleForce;
