import React from 'react';
import axios from "axios";

import Layout from '../../../layouts/authorized';
import {apiHost} from '../../../common/config';
import store from "../../../store/store";
import Select from "../../../components/UI/Select/Select";
import CustomTable from '../../../components/table/table.2.0';
import DatePickerRange from "../../../components/UI/DatePicker/DatePickerRange";
import {savePageState, getPageState, clearPageState} from '../../../common/utilities';


class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            headers: [
                {code: 'regionalManager', caption: 'Региональный менеджер', type: 'text'},
                {code: 'saleForce', caption: 'Sales Force', type: 'text'},
                {code: 'personalName', caption: 'ФИО', type: 'text', link: true},
                {code: 'personalRegisterDate', caption: 'Дата регистрации', type: 'text'},
                {code: 'personalEmail', caption: 'Email', type: 'text'},
                {code: 'legalEntity', caption: 'Партнёр', type: 'text'},
                {code: 'address', caption: 'Адрес', type: 'text'},
                {code: 'legalEntityStatusEe', caption: 'Статус Партнера', type: 'text'},
                {code: 'opticStatusEe', caption: 'Статус ТТ', type: 'text'},
                {code: 'group', caption: 'Роль', type: 'text'},
                {code: 'level', caption: 'Уровень', type: 'text'},
                {code: 'salePoints', caption: 'Баллы продажи', type: 'number'},
                {code: 'educationPoints', caption: 'Баллы обучение', type: 'number', useCaption: true},
                {code: 'points', caption: 'Итого баллов', type: 'number'}
            ],
            tableData: [],
            sf: [
                {value: '', label: 'Все'}
            ],
            rm: [
                {value: '', label: 'Все'}
            ],
            addresses: [
                {value: '', label: 'Все оптики'},
            ],
            entities: [
                {value: '', label: 'Все партнёры'}
            ],
            groups: [
                {value: '', label: 'Все роли'},
                {value: 'DOCTOR_USERS', label: 'Врач'},
                {value: 'CONSULTANT_USERS', label: 'Консультант'}
            ],
            levels: [
                {value: '', label: 'Все уровни'},
                {value: '1', label: 'Специалист'},
                {value: '2', label: 'Профессионал'},
                {value: '3', label: 'Эксперт'}
            ],
            filter: {
                legalEntity: '',
                group: '',
                address: '',
                level: '',
                sf: '',
                rm: '',
                limit: 10,
                page: 1,
            },
            searching: false,
            allRecords: 0,
        }

    }

    componentDidMount() {
        const pageState = getPageState('stat-marketing', this.state);

        if (pageState) {
            this.setState({...pageState});
        } else {
            this.getRegionalManagerUsers();
        }
    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/activity/get/ecp/users/stat`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });

            savePageState(
                'stat-marketing',
                this.state,
                {
                    tableData: res.data.data,
                    allRecords: res.data.allRecords,
                    searching: false
                }
            );
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });

    }

    getAddresses() {
        const {filter} = this.state;

        axios.get(
            `${apiHost}/entity/get/addresses/by/entity/${filter.legalEntity}`,
            {withCredentials: true,}
        ).then(res => {
            const { addresses } = this.state;
            this.setState({
                addresses: [...[{value: '', label: 'Все оптики'}], ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getEntities() {
        const {filter} = this.state;

        axios.get(
            `${apiHost}/entity/getlist/by/sf/${filter.sf}`,
            {withCredentials: true,}
        ).then(res => {
            const { entities } = this.state;
            this.setState({
                entities: [...[{value: '', label: 'Все партнёры'}], ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getSalesForceUsers() {
        const {filter} = this.state;

        axios.get(
            `${apiHost}/user/get/sf/users/by/rm/${filter.rm}`,
            {withCredentials: true,}
        ).then(res => {
            const { sf } = this.state;
            this.setState({
                sf: [...[{value: '', label: 'Все'}], ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getRegionalManagerUsers() {
        axios.get(
            `${apiHost}/user/get/rm/users`,
            {withCredentials: true,}
        ).then(res => {
            const { rm } = this.state;
            this.setState({
                rm: [...rm, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    handleChangeGroup(selectedOption) {
        const {filter} = this.state;
        filter.group = selectedOption.value;
        this.setState({filter});
    }

    handleChangeAddress(selectedOption) {
        const {filter} = this.state;
        filter.address = selectedOption.value;
        this.setState({filter});
    }

    handleChangeEntity(selectedOption) {
        const {filter} = this.state;
        filter.legalEntity = selectedOption.value;
        this.setState({filter});

        this.checkIfNeedClearInputs('legalEntity');
        this.getAddresses();
    }

    handleChangeLevel(selectedOption) {
        const {filter} = this.state;
        filter.level = selectedOption.value;
        this.setState({filter});
    }

    handleChangeSalesForce(selectedOption) {
        const {filter} = this.state;
        filter.sf = selectedOption.value;
        this.setState({filter});

        this.checkIfNeedClearInputs('sf');
        this.getEntities();
    }

    handleChangeRegionalManager(selectedOption) {
        const {filter} = this.state;
        filter.rm = selectedOption.value;
        this.setState({filter});

        this.checkIfNeedClearInputs('rm');
        this.getSalesForceUsers();
    }

    handleChangeDatePickerRange = (startDate, endDate) => {
        const {filter} = this.state;
        filter.dateStart = startDate;
        filter.dateEnd = endDate;
        this.setState({filter});

    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleClear = () => {
        this.checkIfNeedClearInputs('all');
        clearPageState('stat-marketing');
    }

    checkIfNeedClearInputs = (field) => {
        const {filter} = this.state;

        if (field == 'all') {
            filter.rm = '';
            filter.sf = '';
            filter.legalEntity = '';
            filter.address = '';
            filter.group = '';
            filter.level = '';
        }

        if (field == 'rm') {
            filter.sf = '';
            filter.legalEntity = '';
            filter.address = '';
        }

        if (field == 'sf') {
            filter.legalEntity = '';
            filter.address = '';
        }

        if (field == 'legalEntity') {
            filter.address = '';
        }

        this.setState({filter});
    }


    render() {
        const {filter} = this.state;

        return (
            <Layout>
                <h2 className="page-title">Статистика по ECPs</h2>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="rm"
                            label="Выберите РМ"
                            placeholder="Все"
                            options={this.state.rm}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeRegionalManager(selectedOption)}
                            isDisabled={this.state.searching}
                            value={this.state.rm.filter((item) => item.value === filter.rm)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="entity"
                            label="Выберите Sales Force"
                            placeholder="Все"
                            options={this.state.sf}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeSalesForce(selectedOption)}
                            isDisabled={this.state.searching || filter.rm == ''}
                            value={this.state.sf.filter((item) => item.value === filter.sf)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="legalEntity"
                            label="Выберите партнёра"
                            placeholder="Все партнёры"
                            options={this.state.entities}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeEntity(selectedOption)}
                            isDisabled={this.state.searching || filter.sf == ''}
                            value={this.state.entities.filter((item) => item.value === filter.legalEntity)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="addresses"
                            label="Выберите адрес"
                            placeholder=""
                            options={this.state.addresses}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeAddress(selectedOption)}
                            isDisabled={this.state.searching || filter.legalEntity == ''}
                            value={this.state.addresses.filter((item) => item.value === filter.address)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="levels"
                            label="Выберите уровень"
                            placeholder="Все уровни"
                            options={this.state.levels}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeLevel(selectedOption)}
                            isDisabled={this.state.searching}
                            value={this.state.levels.filter((item) => item.value === filter.level)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="groups"
                            label="Выберите роль"
                            placeholder="Все роли"
                            options={this.state.groups}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeGroup(selectedOption)}
                            isDisabled={this.state.searching}
                            value={this.state.groups.filter((item) => item.value === filter.group)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <DatePickerRange
                            onChange={this.handleChangeDatePickerRange}
                            isDisabled={this.state.searching}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5"></div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-8">
                    <div className="w-full md:flex md:justify-between md:w-6/12 md:mr-5">
                        <button disabled={this.state.searching ? "disabled" : ""}
                                className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                onClick={this.handleSearch}>Найти</button>

                        <button className="btn btn-narrow btn-grey w-full mt-3 md:w-6/12 md:mt-0"
                                onClick={this.handleClear}>Сбросить</button>
                    </div>
                    <div className="md:w-6/12 md:ml-5"></div>
                </div>

                {  this.state.tableData.length > 0 &&
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/users?filter=${JSON.stringify(this.state.filter)}`}
                    exportLimit={2000}
                />
                }
            </Layout>
        );
    }
}

export default Page;
