import React from 'react';
import Layout from '../../../layouts/authorized';
import SalesUploadForm from './sales-upload-form';

class Page extends React.Component {

    render() {

        return (
            <Layout>
                <h2 className="page-title">Рекомендации</h2>
                <SalesUploadForm role="doctor" />
            </Layout>
        );
    }
}



export default Page;