import React from 'react';
import { NavLink } from 'react-router-dom';
import './table.scss';
import _ from 'lodash';
import Pagination from "../pagination/pagination";
import moment from 'moment';
import {apiHost} from "../../common/config";
import axios from "axios";
import store from "../../store/store";
import Modal from '../../components/modal/modal';
import Button from "../UI/Button/Button";
import ProcessingModal from '../modal/processingModal';


class CustomTableOwner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalOpen: false,
            showModalText: '',
            tableData: this.props.tableData,
            sortColumn: 'date',
            sortDirection: 'desc',
            currentPage: 1,
            showQuarterModalOpen: false,
            showQuarterModalText: '',
            showPromptModalOpen: false,
            showPromptModalStatus: 'ready',
            showPromptModalError: '',
            canCloseQuarter: false,
            currentSalesSum: [],
            retro: false,
            status: '',
            reloadPage: true
        }

        this.perPage = 10;
    }

    sort(event, code) {
        event.preventDefault();
        // FIXME: to do sort.
        return;
    }

    componentDidMount() {
        this.getEntity()
        this.getCurrentSalesSum(1)
        this.getCurrentSalesSum(2)
        this.getCurrentSalesSum(3)
        this.getCurrentSalesSum(4)
    };

    changePage(page) {
        this.props.onChangePage(page);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.tableData, this.props.tableData)) {
            this.setState({tableData: this.props.tableData})
        }
    }

    declineSale(event, id) {
        event.preventDefault();
        const {status} = this.state.status;
        if (status) {
            return false;
        }
        this.setState({status: 'declineSale'});

        axios.get(
            `${apiHost}/sale/decline/userSales/` + id,
            {withCredentials: true}
        ).then(res => {
            if (res.data.status === 'success') {
                const {tableData} = this.state;

                this.setState({
                    showModalOpen: true,
                    showModalText: 'Продажа отклонена.',
                    uploadedStatus: true,
                    status: '',
                    reloadPage: false,
                    tableData: tableData.map((item) => {
                        if (id === item.id) {
                            item.status = 'Отклонена';
                            item.statusValue = '0';
                        }

                        return item;
                    })
                });
            } else {
                this.setState({
                    showModalOpen: true,
                    showModalText: res.data.error,
                    status: '',
                    reloadPage: false
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    approveAllSales(event) {
        event.preventDefault();
        const {status} = this.state.status;
        if (status) {
            return false;
        }
        this.setState({status: 'approveAllSales'});

        axios.get(
            `${apiHost}/sale/approve/allUserSales`,
            {withCredentials: true}
        ).then(res => {
            if (res.data.status === 'success') {
                this.setState({
                    showModalOpen: true,
                    showModalText: 'Продажи одобрены.',
                    uploadedStatus: true,
                    status: ''
                });
            } else {
                this.setState({
                    showModalOpen: true,
                    showModalText: res.data.error,
                    status: ''
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    approveSale(event, id) {
        event.preventDefault();
        const {status} = this.state.status;
        if (status) {
            return false;
        }

        this.setState({status: 'approveSale'});

        axios.get(
            `${apiHost}/sale/approve/userSales/` + id,
            {withCredentials: true}
        ).then(res => {
            if (res.data.status === 'success') {
                const {tableData} = this.state;

                this.setState({
                    showModalOpen: true,
                    showModalText: 'Продажа одобрена.',
                    uploadedStatus: true,
                    status: '',
                    reloadPage: false,
                    tableData: tableData.map((item) => {
                        if (id === item.id) {
                            item.status = 'Принята';
                            item.statusValue = '1';
                        }

                        return item;
                    })
                });
            } else {
                this.setState({
                    showModalOpen: true,
                    showModalText: res.data.error,
                    status: '',
                    reloadPage: false,
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getQuarter() {
        axios.get(
            `${apiHost}/sale/check_current_quarter`,
            {withCredentials: true}
        ).then(res => {
            this.setState({
                canCloseQuarter: res.data.status,
                showPromptModalStatus: 'ready',
                showPromptModalError: res.data.error
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })

            this.setState({showPromptModalStatus: 'ready'});
        });

    }

    getEntity() {
        axios.get(
            `${apiHost}/entity/getUserEntity`,
            {withCredentials: true}
        ).then(res => {
            if(res.data.RETRO == '1')
            {
                this.setState({
                    retro: true
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getCurrentSalesSum($number) {
        axios.get(
            `${apiHost}/sale/get_sales_by_quarter/${$number}`,
            {withCredentials: true}
        ).then(res => {
            this.setState({
                currentSalesSum: [...this.state.currentSalesSum, res.data ? res.data : {}]
            })
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    closeQuarter(event) {
        event.preventDefault();
        const {status} = this.state.status;
        if (status) {
            return false;
        }

        this.setState({showPromptModalStatus: 'processing'});

        axios.get(
            `${apiHost}/sale/close_sales_for_current_quartal`,
            {withCredentials: true}
        ).then(res => {
            this.setState({status: 'closeQuarter', showPromptModalOpen: false, showPromptModalStatus: 'ready'});
            if (res.data.status === 'success') {
                this.setState({
                    showQuarterModalOpen: true,
                    showQuarterModalText: 'Квартал закрыт.',
                    uploadedStatus: true,
                    status: ''
                });
            } else {
                this.setState({
                    showQuarterModalOpen: true,
                    showQuarterModalText: res.data.error,
                    status: ''
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    async exportStat(url) {
        let fileName = '';

        const blob = await axios.get(
            url,
            {
                withCredentials: true,
            }
        ).then(res => {
            fileName = res.headers.filename;
            return new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res.data], {type: res.headers['content-type']})
        })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });

        const link = document.createElement('a');


        link.download = fileName || 'download';
        link.href = URL.createObjectURL(blob);
        link.click();

        URL.revokeObjectURL(link.href);
    }

    closeQuarterModal() {
        this.setState({showQuarterModalOpen: false});
        window.location.reload();
    }

    closePromptModal() {
        this.setState({showPromptModalOpen: false, uploadedStatus: false, showPromptModalStatus: 'ready'});
    }

    showPromptModal(event)
    {
        event.preventDefault();
        this.setState({showPromptModalOpen: true, showPromptModalStatus: 'processing'});
        this.getQuarter();
    }

    closeModal() {
        const {uploadedStatus, reloadPage} = this.state;

        if (uploadedStatus && reloadPage) {
            window.location.reload();
        }

        this.setState({showModalOpen: false, uploadedStatus: false, reloadPage: true});
    }

    renderTable() {
        const {tableData, sortDirection, sortColumn, currentPage} = this.state;
        const {headers} = this.props;
        const startSlice = (currentPage - 1) * this.perPage;
        const endSlice = startSlice + this.perPage;

        return (
            <>
                <ProcessingModal showModalOpen={this.state.status} />

                <Modal isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        <h2 className="title-semibold title-semibold__black">Подтверждение
                            продаж!</h2>
                        <p className="text-center">{this.state.showModalText}</p>

                        <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                                onClick={() => this.closeModal()}>Закрыть</Button>
                    </div>
                </Modal>

                <Modal isOpen={this.state.showPromptModalOpen}
                       onClose={() => this.closePromptModal()}
                       processing={this.state.showPromptModalStatus === 'processing'}
                       hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        {
                            this.state.canCloseQuarter
                            ?
                                <>
                                    <h2 className="title-semibold title-semibold__black">Закрытие квартала</h2>
                                    <p className="text-center">
                                        Закрытие квартала приведёт к полной блокировке статусов уже внесённых продаж. Также после закрытия квартала Ваши сотрудники больше не смогут внести новые продажи в закрытый квартал.
                                        <br>
                                        </br>
                                        <br>
                                        </br>
                                        Отменить данное действие будет невозможно.
                                    </p>
                                    <button type={"button"} className="btn btn-blue btn-narrow mt-8 mr-1"
                                        onClick={(event) => this.closeQuarter(event)}>Закрыть квартал</button>
                                    <button type={"button"} className="btn btn-grey btn-narrow mt-8"
                                        onClick={(event) => this.closePromptModal()}>Не закрывать квартал</button>
                                </>
                            :
                                <p>{this.state.showPromptModalError}</p>
                        }
                    </div>
                </Modal>

                <Modal isOpen={this.state.showQuarterModalOpen} onClose={() => this.closeQuarterModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        <h2 className="title-semibold title-semibold__black">Закрытие квартала</h2>
                        <p className="text-center">{this.state.showQuarterModalText}</p>

                        <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                                onClick={() => this.closeQuarterModal()}>Закрыть</Button>
                    </div>
                </Modal>

                <div className="w-full md:flex md:w-3/4 mb-10 mt-3">
                    <button type={"button"} className="btn btn-blue btn-narrow mt-8 float-left mr-2"
                            onClick={(event) => this.approveAllSales(event)}>Подтвердить все продажи</button>
                    <button
                        hidden={!this.state.retro}
                        type={"button"} className="btn btn-orange btn-narrow mt-8 float-left"
                        onClick={(event) => this.showPromptModal(event)}>Закрыть квартал</button>
                </div>

                <div hidden={!this.state.retro} className="clear-both w-full mb-6">
                    {
                        this.state.currentSalesSum.filter((quarter) => quarter.hasOwnProperty('name'))
                        .sort((prev, next) => prev.order > next.order)
                        .map(function(quarter, i) {
                            return (
                                <div>
                                    {quarter.name}: Сумма склад: {quarter.sum_storage} Сумма RX: {quarter.sum_rx}
                                </div>
                            )
                        })
                    }
                </div>

                <div className="custom-table">
                    <table>
                        <thead>
                        <tr>
                            {headers.map(item => (
                                <th key={item.code}>
                                    <a onClick={(event) => this.sort(event, item.code)}>
                                        {item.caption}
                                    </a>
                                </th>
                            ))}

                            <th>Действия</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.slice(startSlice, endSlice).map((row, index) => (
                            <tr key={index}>
                                {headers.map((col, index) => {

                                    if (_.has(col, 'useCaption')) {
                                        const caption = `${col.code}Caption`;

                                        return (
                                            <td key={index}
                                                className={`${_.has(col, 'addStyleClass') ? col.addStyleClass : null}`}
                                                dangerouslySetInnerHTML={{__html: row[caption]}}></td>
                                        )
                                    } else {

                                        if (_.has(col, 'link')) {
                                            const link = `${col.code}Link`;

                                            return (
                                                <td key={index}>
                                                    <NavLink to={row[link]}>
                                                        {row[col.code]}
                                                    </NavLink>
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td key={index}
                                                    className={`${_.has(col, 'addStyleClass') ? col.addStyleClass : null}`}>
                                                    {row[col.code]}
                                                </td>
                                            )
                                        }

                                    }

                                })}
                                <td key={index}>
                                    {(() => {
                                        if (row.quarter == null) {
                                            if (row.statusValue === '0') {
                                                return (
                                                    <button type={"button"} className="btn btn-blue btn-narrow mt-8 float-right"
                                                            onClick={(event) => this.approveSale(event, row.id)}>✓</button>
                                                )
                                            } else if (row.statusValue === '1') {
                                                return (
                                                    <button type={"button"} className="btn btn-orange btn-narrow mt-8 float-right"
                                                            onClick={(event) => this.declineSale(event, row.id)}>✕</button>
                                                )
                                            } else {
                                                return (
                                                    [
                                                        <button type={"button"} className="btn btn-blue btn-narrow mt-8 float-right"
                                                                onClick={(event) => this.approveSale(event, row.id)}>✓</button>,
                                                        <button type={"button"} className="btn btn-orange btn-narrow mt-8 float-right"
                                                                onClick={(event) => this.declineSale(event, row.id)}>✕</button>
                                                    ]
                                                )
                                            }
                                        }
                                    })()}

                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className="clear-both w-full mb-6">
                    <Pagination totalPages={this.props.allRecords / this.props.perPage}
                                currentPage={this.props.currentPage}
                                invertColor={true}
                                onNavigate={(page) => this.changePage(page)} className="mt-6 float-left"/>
                    {
                        this.props.exportLink &&
                        <button className="btn btn-blue btn-narrow mt-8 float-right"
                                onClick={() => this.exportStat(this.props.exportLink)}>Выгрузить в Excel</button>
                    }
                </div>
            </>
        );
    }

    render() {

        return (
            this.props.showSpin
                ?
                <div className="w-full md:w-3/4 flex justify-center">
                    <object type="image/svg+xml" data="/assets/resources/Infinity-2s-200px.svg">
                        <img src="/assets/resources/Infinity-2s-200px.svg" alt="Поиск ..."/>
                    </object>
                </div>
                : this.renderTable()
        );
    }
};

CustomTableOwner.defaultProps = {
    showSpin: false,
    perPage: 10
};

export default CustomTableOwner;
