import React from 'react';
import {NavLink} from 'react-router-dom';
import './education.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import './education-trainer-course-detail-homework.scss';

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/');

        this.courseCode = path[5];
        this.fileId = path[7];

        this.state = {
            course: {},
            file: {},
            isRequestSend: false,
        };

        this.fileRef = React.createRef();
    }

    componentDidMount() {

        const getCourse = function (courseCode) {
            return axios.get(
                `${apiHost}/education/trainer/get/course/${courseCode}`, {
                    withCredentials: true
                }
            )
        };

        const getFile = function (id) {
            return axios.get(
                `${apiHost}/education/trainer/get/course/material/ibelement/${id}`, {
                    withCredentials: true
                }
            )
        };

        axios.all([getCourse(this.courseCode), getFile(this.fileId)])
            .then(axios.spread((acct, perms) => {
                this.setState({course: acct.data.course, file: perms.data});
            }));
    }


    render() {
        const {course, file} = this.state;

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs" dangerouslySetInnerHTML={{__html: file.name}}></h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                    <li>></li>
                    <li><NavLink to="/lk/education/trainer">Обучение от бизнес тренера</NavLink></li>
                    <li>></li>
                    <li><NavLink to={`/lk/education/trainer/course/${course.code}`}>{course.name}</NavLink></li>
                </ul>
                <div>
                    { typeof(file.file) !== 'undefined' && file.file.src.substr(-3) === 'pdf' &&
                        <div className="w-full flex">
                            <embed src={file.file.src}
                            type="application/pdf"
                            width="100%"
                            height="600px" />
                        </div>

                    }

                    { typeof(file.file) !== 'undefined' && file.file.src.substr(-3) === 'jpg' &&
                        <img src={file.file.src}/>
                    }

                    {typeof (file.file) !== 'undefined' &&
                        <a href={file.file.src} target='_blank'>Открыть материал</a>
                    }
                </div>
            </>
        );
    }
}


export default Page;
