import React from 'react';
import {NavLink} from 'react-router-dom';
import {apiHost} from '../../../common/config';
import Layout from '../../../layouts/authorized';
import axios from 'axios';
import store from "../../../store/store";
import CustomTable from '../../../components/table/table';
import _ from 'lodash';
import ProfileProgressBar from "../../../components/profile-progress-bar/profile-progress-bar";
import ResizeDetector from "react-resize-detector";
import ProfileProgressCircle from "../../../components/profile-progress-circle/profile-progress-circle";
import ProfileProgressCup from "../../../components/profile-progress-cup/profile-progress-cup";
import ProfileProgressLine from "../../../components/profile-progress-line/profile-progress-line";

class Page extends React.Component {

    constructor (props) {
        super(props);

        this.userId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);

        this.headersStat = [
            {code: 'date', caption: 'Дата', type: 'date'},
            {code: 'level', caption: 'Уровень', type: 'text'},
            {code: 'event', caption: 'Тип события', type: 'text'},
            {code: 'points', caption: 'Баллы', type: 'number', addStyleClass: 'text-center'},
        ];

        this.headersEducation = [
            {code: 'section', caption: 'Раздел обучения', type: 'text'},
            {code: 'name', caption: 'Название курса', type: 'text'},
            {code: 'pass', caption: 'Отметка о прохождении', type: 'text', useCaption: true, addStyleClass: 'text-center'},
            {code: 'points', caption: 'Кол-во баллов', type: 'number', addStyleClass: 'text-center'},
        ];

        this.headersSale = [
            {code: 'date', caption: 'Дата', type: 'date'},
            {code: 'design', caption: 'Дизайн', type: 'text'},
            {code: 'covering', caption: 'Покрытие', type: 'text'},
            {code: 'count', caption: 'Кол-во линз', type: 'number'},
            {code: 'technology', caption: 'Технология', type: 'text'},
            {code: 'points', caption: 'Баллы', type: 'number'},
        ];

        this.headersTopSections = [
            {code: 'number', caption: '№', type: 'number',  addStyleClass: 'text-center'},
            {code: 'name', caption: 'Название раздела', type: 'text'},
        ];

        this.state = {
            userData: {
                ROLE: {
                    code: '',
                    label: ''
                },
                EMAIL: '',
                NAME: '',
                PERSONAL_PHONE: '',
                LEGAL_ENTITY: '',
                USER_PHOTO: '',
                UF_USER_ECP_LEVEL: 0,
            },
            news: [],
            page: 1,
            files: [],
            promotions: [],
            courses: [],
            educationPoints: {
                maxPoints: 9999,
                points: 0,
            },
            salePoints: {
                maxPoints: 9999,
                points: 0,
            },
            allPoints: {
                maxPoints: 0,
                points: 0,
            },
            keySalePoints: 0,
            sections: [
                {code: 'stat', caption: 'Статистика'},
                {code: 'education', caption: 'Обучение'},
                {code: 'sale', caption: 'Продажи'},
                {code: 'topSections', caption: 'Рейтинг разделов'}
            ],
            selectedSection: 'stat',
            headers: this.headersStat,
            tableData: [],
            token: Date.now(),
            cups: [],
            certificates: {
                fast_start: {
                    status: false
                },
                varalux: {
                    status: false
                },
                essilor_essensial: {
                    status: false
                },
                professional: {
                    status: false
                },
                expert: {
                    status: false
                },
                essilor_professional_doc: {
                    status: false
                },
                essilor_professional_con: {
                    status: false
                }
            }
        }
    }

    componentDidMount()
    {
        axios.get(
            `${apiHost}/activity/get/user/info`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        ).then(res => {
            this.setState({userData: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        this.getEducationPoints();
        this.getSalePoints();
        this.getTableDataStat();
        this.getCups();
        this.getCertificates();
        this.getKeySalePoints();
    }

    getEducationPoints(){
        axios.get(
            `${apiHost}/activity/get/user/education/points`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        ).then(res => {
            const { allPoints } = this.state;
            allPoints.maxPoints += res.data.maxPoints;
            allPoints.points += res.data.points;

            this.setState({educationPoints: res.data, allPoints});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getSalePoints(){
        axios.get(
            `${apiHost}/activity/get/user/sale/points`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        ).then(res => {
            const { allPoints } = this.state;
            allPoints.maxPoints += res.data.maxPoints;
            allPoints.points += res.data.points;

            this.setState({salePoints: res.data, allPoints});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    selectSection(event, section) {
        event.preventDefault();

        if (section === 'education') {
            this.setState({headers: this.headersEducation});
            this.getTableDataEducation();
        } else if (section === 'sale') {
            this.setState({headers: this.headersSale});
            this.getTableDataSale();
        }
        else if (section === 'topSections') {
            this.setState({headers: this.headersTopSections});
            this.getTableDataTopSections();
        } else {
            this.setState({headers: this.headersStat});
            this.getTableDataStat();
        }

        this.setState({selectedSection: section});
    }


    getTableDataStat() {
        axios.get(
            `${apiHost}/activity/get/user/stat`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getTableDataEducation() {
        axios.get(
            `${apiHost}/activity/get/user/stat/education`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }


    getTableDataSale() {
        axios.get(
            `${apiHost}/activity/get/user/stat/sale`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getTableDataTopSections() {
        axios.get(
            `${apiHost}/activity/get/user/stat/sections/top`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getKeySalePoints(){
        axios.get(
            `${apiHost}/activity/get/user/keySale/points`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        )
        .then(res => {
            this.setState({keySalePoints: res.data});
        })
        .catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getCups() {
        axios.get(
            `${apiHost}/activity/get/user/cups`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        )
            .then(res => {
                this.setState({cups: res.data});
            })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });
    }


    getCertificates(){
        axios.get(
            `${apiHost}/activity/get/user/certificates`,
            {
                withCredentials: true,
                params: {
                    userId: this.userId
                }
            }
        )
            .then(res => {
                this.setState({certificates: res.data})
            })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });
    }

    render() {

        const {salePoints, educationPoints, allPoints, selectedSection, cups, certificates, keySalePoints} = this.state,
            saleBarCaption = this.state.userData.ROLE.code === 'DOCTOR_USERS' ? 'Рекомендации' : 'Продажи',
            showKeySaleProgressBlock = this.state.userData.UF_USER_ECP_LEVEL == '3';

        let exportLink;
        switch (selectedSection) {
            case 'stat':
                exportLink = `${apiHost}/export/user?userId=${this.userId}`;
                break;
            case 'education':
                exportLink = `${apiHost}/export/education?userId=${this.userId}`;
                break;
            case 'sale':
                exportLink = `${apiHost}/export/sale?userId=${this.userId}`;
                break;
            case 'topSections':
                exportLink = `${apiHost}/export/top?userId=${this.userId}`;
                break;
            default:
                exportLink = false;
        }

        return (
            <Layout>
                <h2 className="page-title page-title--breadcrumbs">Cотрудник</h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/stat-marketing">Статистика по ECPs</NavLink></li>
                </ul>


                <div className="white-box mt-6 clearfix w-full">
                    <div className="float-left w-1/4 text-center">
                        {this.state.userData.USER_PHOTO ? (
                            <img className="user-photo" src={this.state.userData.USER_PHOTO}/>
                        ) : (
                            <img className="user-photo" src='/assets/resources/no-ava.jpg'/>
                        )
                        }
                    </div>
                    <div className="float-right w-3/4">
                        <div className="title-semibold title-semibold__black">{this.state.userData.NAME}</div>
                        <div className="mt-3">
                            <ul className="flex flex-col md:flex-row">
                                <li className="flex-initial mr-5 py-1 md:py-0">
                                    <span className="icon-user text-essilor-light-blue pr-1"/>
                                    <span className="pr-4">{this.state.userData.ROLE.label}</span>
                                </li>
                                <li className="flex-initial mr-5 py-1 md:py-0">
                                    <span className="pr-4">{this.state.userData.LEGAL_ENTITY}</span>
                                </li>
                                <li className="flex-initial mr-5 py-1 md:py-0">
                                    <span className="icon-mail text-essilor-light-blue pr-1"/>{this.state.userData.EMAIL}
                                </li>
                                <li className="flex-initial py-1 md:py-0">
                                    <span className="icon-phone text-essilor-light-blue pr-1"/>{this.state.userData.PERSONAL_PHONE}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <ResizeDetector handleWidth>
                        {
                            ({ width }) => {
                                return (
                                    <div className={`border-t-2 border-dashed mt-6 ${width < 1000 ? `float-right w-full` : `float-right w-3/4`}`}>

                                        <div className="mt-5">
                                            <ProfileProgressBar
                                                currentPosition={this.state.userData.UF_USER_ECP_LEVEL}
                                                roleCode={this.state.userData.ROLE.code}
                                                width={width}/>

                                            <div className="mt-10">
                                                <div className="mx-auto md:mx-0 md:float-left w-64 max-w-full md:w-1/4 md:pr-6 mb-6">
                                                    <ProfileProgressCircle value={allPoints.points} valueMax={allPoints.maxPoints}/>
                                                </div>

                                                { cups &&
                                                <ul className="md:float-right w-full md:w-3/4 overflow-hidden md:overflow-visible">
                                                    {
                                                        cups.map(cup => (
                                                            <li className="float-left mb-2">
                                                                <ProfileProgressCup tip={cup.tip} inactive={!cup.status}/>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>

                                                }

                                                <div className="md:float-right md:w-3/4 md:clear-none mt-5 w-full clear-both">
                                                    <ProfileProgressLine valueMax={educationPoints.maxPoints} value={educationPoints.points} title="Обучение"/>
                                                </div>
                                                <div className="md:float-right w-full md:w-3/4">
                                                    <ProfileProgressLine valueMax={salePoints.maxPoints} value={salePoints.points} title={saleBarCaption} color="green"/>
                                                </div>
                                                {
                                                    showKeySaleProgressBlock &&
                                                    <div className="md:float-right w-full md:w-3/4">
                                                        <ProfileProgressLine
                                                            valueMax={16}
                                                            value={keySalePoints}
                                                            title={`Ключевые ${saleBarCaption.toLowerCase()}`}
                                                            color="green"
                                                            counting="линз"
                                                        />
                                                    </div>
                                                }

                                            </div>
                                        </div>

                                        <div className="mt-5 clear-both">
                                            <div className="title-semibold title-semibold__black">Сертификаты</div>
                                            <ul className="cert-wrapp justify-between flex flex-wrap">
                                                { this.state.userData.ROLE.code !== 'DOCTOR_USERS' &&
                                                <>
                                                    <li className={`${!certificates.fast_start.status && 'inactive'}`}>
                                                        <img src="/assets/resources/diplomas/diploma-1.png"
                                                             srcSet="/assets/resources/diplomas/diploma-1.png, /assets/resources/diplomas/diploma-1@2x.png 2x"
                                                             onClick={() => this.openCert('fast_start')}/>
                                                    </li>
                                                    <li className={`${!certificates.essilor_professional_con.status && 'inactive'}`}>
                                                        <img src="/assets/resources/diplomas/diploma-es-pr.png"
                                                             srcSet="/assets/resources/diplomas/diploma-es-pr.png, /assets/resources/diplomas/diploma-es-pr@2x.png 2x"
                                                             onClick={() => this.openCert('essilor_professional_con')}/>
                                                    </li>
                                                </>
                                                }
                                                { this.state.userData.ROLE.code !== 'CONSULTANT_USERS' &&
                                                <>
                                                    <li className={`${!certificates.varalux.status && 'inactive'}`}>
                                                        <img src="/assets/resources/diplomas/diploma-5.png"
                                                             srcSet="/assets/resources/diplomas/diploma-5.png, /assets/resources/diplomas/diploma-5@2x.png 2x"
                                                             onClick={() => this.openCert('varalux')}/>
                                                    </li>
                                                    <li className={`${!certificates.essilor_professional_doc.status && 'inactive'}`}>
                                                        <img src="/assets/resources/diplomas/diploma-es-pr.png"
                                                             srcSet="/assets/resources/diplomas/diploma-es-pr.png, /assets/resources/diplomas/diploma-es-pr@2x.png 2x"
                                                             onClick={() => this.openCert('essilor_professional_doc')}/>
                                                    </li>
                                                </>
                                                }
                                                <li className={`${!certificates.professional.status && 'inactive'}`}>
                                                    <img src="/assets/resources/diplomas/diploma-3.png"
                                                         srcSet="/assets/resources/diplomas/diploma-3.png, /assets/resources/diplomas/diploma-3@2x.png 2x"/>
                                                </li>
                                                <li className={`${!certificates.expert.status && 'inactive'}`}>
                                                    <img src="/assets/resources/diplomas/diploma-4.png"
                                                         srcSet="/assets/resources/diplomas/diploma-4.png, /assets/resources/diplomas/diploma-4@2x.png 2x"/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    </ResizeDetector>
                </div>

                <div className="sections-menu">
                    {
                        this.state.sections.map(section => {
                                const isActive = selectedSection === section.code ? 'active' : '';

                                return (
                                    <a href="#" className={`sections-menu__item ${isActive}`}
                                       key={`section-${section.code}`}
                                       onClick={(event) => this.selectSection(event, section.code)}>{section.caption}</a>
                                )
                            }
                        )
                    }
                </div>

                <div className="w-full">

                    { this.state.selectedSection === 'topSections' &&
                    <p className="mt-5 mb-5">Здесь указаны разделы, которые сотрудник посещает чаще всего.</p>
                    }

                    {  this.state.tableData.length > 0 &&
                    <CustomTable
                        headers={this.state.headers}
                        tableData={this.state.tableData}
                        exportLink={exportLink}
                    />
                    }

                </div>

            </Layout>
        );
    }
}

export default Page;
