function savePageState(pageCode, pageState, overrideParams) {
    localStorage.setItem(pageCode, JSON.stringify({...pageState, ...overrideParams}));
}

function getPageState (pageCode, currentState) {
    const storageState = localStorage.getItem(pageCode);

    if (!!storageState) {
        const state = JSON.parse(storageState);
        return state;
    }

    return null;
}

function clearPageState(pageCode) {
    localStorage.setItem(pageCode, '');
}

export {savePageState, getPageState, clearPageState}