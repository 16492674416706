import React from 'react';
import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Layout from '../../../layouts/authorized';
import Select from '../../../components/UI/Select/Select';
import CustomTable from '../../../components/table/table.2.0';
import axios from 'axios';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            responseError: '',
            headers: [
                {code: 'legalEntity', caption: 'Партнёр', type: 'text'},
                {code: 'promoName', caption: 'Название акциии', type: 'text'},
                {code: 'promoDate', caption: 'Срок проведения акции', type: 'text'},
                {code: 'inviteSent', caption: 'Приглашение отправлено', type: 'text'},
                {code: 'inviteJoined', caption: 'Участвует (дата подтверждения)', type: 'text'},
                {code: 'condition', caption: 'Механика', type: 'text'},
                {code: 'optic', caption: 'Участвующая оптика', type: 'text'},
                {code: 'legalEntityStatusEe', caption: 'Статус Партнера', type: 'text'},
                {code: 'opticStatusEe', caption: 'Статус ТТ', type: 'text'}
            ],
            tableData: [],
            entities: [
                {value: '', label: 'Все'},
            ],
            promoName: [
                {value: '', label: 'Все'},
            ],
            inviteSent: [
                {value: '', label: 'Все'},
                {value: 'Y', label: 'Отправлено'},
                {value: 'N', label: 'Не отправлено'},
            ],
            inviteJoined: [
                {value: '', label: 'Все'},
                {value: 'Y', label: 'Участвует'},
                {value: 'N', label: 'Не участвует'},
            ],
            filter: {
                legalEntity: '',
                promoName: '',
                inviteSent: '',
                inviteJoined: '',
                limit: 10,
                page: 1,
            },
            searching: true,
            allRecords: 0,
        }
    }

    componentDidMount = () => {
        const {filter} = this.state;
        this.getTableData(filter);
        this.getEntities();
        this.getPromo();
    };

    getEntities() {
        axios.get(
            `${apiHost}/entity/getlist`,
            {withCredentials: true,}
        ).then(res => {
            const { entities } = this.state;
            this.setState({
                entities: [...entities, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getPromo() {
        axios.get(
            `${apiHost}/promotions/get/list`,
            {
                withCredentials: true,
                params: {
                    excludeInactive: false
                }
            }
        ).then(res => {
            const { promoName } = this.state;
            this.setState({
                promoName: [...promoName, ...res.data.map(item => ({value: item.id, label: item.name}))]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    handleChangeEntity(selectedOption) {
        const {filter} = this.state;
        filter.legalEntity = selectedOption.value;
        this.setState({filter});
    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/promotions/get/activity`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });
    }

    handleChangeSalesForce(selectedOption) {
        const {filter} = this.state;
        filter.sf = selectedOption.value;
        this.setState({filter});
    }

    handleChangeRegionalManager(selectedOption) {
        const {filter} = this.state;
        filter.rm = selectedOption.value;
        this.setState({filter});
    }

    handleChangePromoName(selectedOption) {
        const {filter} = this.state;
        filter.promoName = selectedOption.value;
        this.setState({filter});
    }

    handleChangeInviteSent(selectedOption) {
        const {filter} = this.state;
        filter.inviteSent = selectedOption.value;
        this.setState({filter});
    }

    handleChangeInviteJoin(selectedOption) {
        const {filter} = this.state;
        filter.inviteJoined = selectedOption.value;
        this.setState({filter});
    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    render() {

        return (
            <Layout>

                <h2 className="page-title">Статистика по Акциям</h2>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="entity"
                            label="Выберите партнёра"
                            placeholder="Все"
                            options={this.state.entities}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeEntity(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="promoName"
                            label="Выберите акцию"
                            placeholder="Все"
                            options={this.state.promoName}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangePromoName(selectedOption)}
                        />
                    </div>
                </div>
                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="inviteSent"
                            label="Статус приглашения"
                            placeholder="Все"
                            options={this.state.inviteSent}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeInviteSent(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="inviteJoined"
                            label="Статус участия"
                            placeholder="Все"
                            options={this.state.inviteJoined}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeInviteJoin(selectedOption)}
                        />
                    </div>
                </div>
                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.state.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={this.handleSearch}>Найти</button>

                        </div>
                    </div>

                    <div className="md:w-6/12 md:ml-5"></div>
                </div>

                {  this.state.tableData.length > 0 &&
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/promotions?filter=${JSON.stringify(this.state.filter)}`}
                />
                }
            </Layout>
        );
    }
}



export default Page;
