import React from 'react';
import _ from 'lodash';
import axios from 'axios';

import {NavLink} from 'react-router-dom';
import Select from '../../../components/UI/Select/Select';
import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input/Input';
import Modal from '../../../components/modal/modal';
import Spinner from '../../../components/spinner/spinner';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            type: {value: '', label: 'Все'},
            sort: this.getSorts()[0],
            title: {value: '', label: 'Все'},
            items: [],
            showModalOpen: false,
            modal: {
                title: '',
                text: ''
            },
            selectedCourse: '',
            webinarModalOpen: false,
            webinarModalStatus: '',
            listAdditionalFieldsForWebinar: [],
            userAdditionalFieldsForWebinar: {},
            webinarModalSendButton: true,
            titles: [
                {value: '', label: 'Все'},
            ],
            searching: true
        }
    }


    getTypes() {
        return [
            {
                value: '',
                label: 'Все виды обучения'
            },
            {
                value: 'courses',
                label: 'Курсы'
            },
            {
                value: 'webinars',
                label: 'Вебинары'
            },
            {
                value: 'tests',
                label: 'Тесты'
            },
            {
                value: 'records',
                label: 'Записи вебинаров'
            }
        ]
    }

    getSorts() {
        return [
            {
                value: 'start_date_asc',
                label: <><i className="icon-sort-asc"/> По дате</>
            },
            {
                value: 'start_date_desc',
                label: <><i className="icon-sort-desc"/> По дате</>
            },
            {
                value: 'name_asc',
                label: <><i className="icon-sort-asc"/> По названию</>
            },
            {
                value: 'name_desc',
                label: <><i className="icon-sort-desc"/> По названию</>
            }
        ]
    }


    handleChangeType(type) {
        this.setState({type})
    }

    handleChangeSort(sort) {
        this.setState({sort})
    }

    handleChangeTitle(title) {
        this.setState({title})
    }

    joinCourse(courseId) {
        axios.get(
            `${apiHost}/webinar/course/register/${courseId}`,
            {
                withCredentials: true,
            }
        ).then(res => {
            const modal = {title: 'Регистрация на курс', 'text': 'Вам на почту было отправлено сообщение.'}

            if (res.data.status === 'fail') {
                modal.text = 'Мы не смогли Вас зарегистрировать на курс, пожалуйста обратитесь в службу поддержки.';
            }

            this.setState({showModalOpen: true, modal});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    closeModal() {
        this.setState({showModalOpen: false});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.type !== prevState.type || this.state.sort !== prevState.sort
            || this.state.title !== prevState.title) {
            this.fetchData();
        }
    }

    fetchData() {
        this.setState({searching: true});

        axios.get(
            `${apiHost}/academy/get/list`,
            {
                withCredentials: true,
                params: {
                    type: this.state.type.value,
                    sort: this.state.sort.value,
                    id: this.state.title.value
                }
            }
        ).then(res => {
            this.setState({items: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({searching: false})
        });
    }

    componentDidMount() {
        const params = new URLSearchParams(document.location.search);
        const id = params.get("id");
        const type = params.get("type");

        this.getTitles(id);

        if (!id) {
            this.fetchData();
        }

        if (type != "" && type != null) {
            this.setState({type: {
                    value: 'records',
                    label: 'Записи вебинаров'
            }});
        }
    }

    getTitles(defaultId) {
        axios.get(
            `${apiHost}/academy/get/titles`,
            {withCredentials: true,}
        ).then(res => {
            const { titles } = this.state;
            this.setState({
                titles: [...titles, ...res.data]
            });

            if (defaultId && res.data.filter((item) => item.value === defaultId).length > 0) {
                this.setState({
                    title: res.data.filter((item) => item.value === defaultId)[0]
                });
            }

        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    registerWebinar(eventId) {
        const userAdditionalFields = this.state.userAdditionalFieldsForWebinar;

        axios.get(
            `${apiHost}/webinar/register/${eventId}`,
            {
                withCredentials: true,
                params: {
                    userAdditionalFields: JSON.stringify(userAdditionalFields),
                    iblock: 14
                }
            }
        ).then(res => {
            let additionalFields = [];

            if (res.data.status) {

                if (res.data.status === 'needAdditionalFields') {
                    additionalFields = res.data.additionalFields || [];
                }

                this.setState({
                    webinarModalOpen: true,
                    webinarModalMessage: res.data.message || '',
                    webinarModalLink: res.data.link  || '',
                    webinarModalStatus: res.data.status,
                    listAdditionalFieldsForWebinar: additionalFields,
                    selectedCourse: eventId
                })
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    checkModalSendButton(event) {
        const fieldName = event.target.name;
        const {userAdditionalFieldsForWebinar, listAdditionalFieldsForWebinar} = this.state;
        let {webinarModalSendButton} = this.state;

        userAdditionalFieldsForWebinar[fieldName] = event.target.value;

        let countUserAddFields = _.without(_.values(userAdditionalFieldsForWebinar), '').length;

        if (countUserAddFields === listAdditionalFieldsForWebinar.length) {
            webinarModalSendButton = false;
        } else {
            webinarModalSendButton = true;
        }

        this.setState({userAdditionalFieldsForWebinar, webinarModalSendButton});
    }

    webinarModalClose() {
        this.setState({
            webinarModalOpen: false,
            selectedCourse: false,
            webinarModalStatus: '',
            listAdditionalFieldsForWebinar: [],
            userAdditionalFieldsForWebinar: {},
            webinarModalSendButton: true
        });
    }

    renderMessageAfterRegistration() {
        return (
            <>
            <p className="pb-5">{this.state.webinarModalMessage}</p>
            <a className="btn btn-thin btn-blue"
               href={this.state.webinarModalLink} target="_blank">Перейти к вебинару</a>
            </>
        );
    }


    renderAdditionalFields() {
        const {listAdditionalFieldsForWebinar, selectedCourse} = this.state;

        return (
            <>
            <p className="pb-5">Для регистрации на вебинар необходимо заполнить дополнительные поля:</p>

            {
                listAdditionalFieldsForWebinar.map((field) => {

                    return (
                        <Input label={field.label}
                               name={field.key}
                               type="text"
                               className="w-full max-w-3xl"
                               key={field.key}
                               onInput={(e) => this.checkModalSendButton(e)}/>
                    );
                })
            }

            <Button onClick={() => this.registerWebinar(selectedCourse)}
                    className="btn btn-thin btn-blue mt-4 sm:mr-8"
                    disabled={this.state.webinarModalSendButton}>
                Регистрация в вебинаре
            </Button>
            </>
        );
    }

    renderCourse(item) {
        return (
            <>
                {   item.isPassed
                    ?
                        "Курс пройден"
                    :
                        <>
                            {
                                item.courseLink === '' &&
                                <a onClick={() => this.joinCourse(item.id)}
                                   className="px-3 cursor-pointer no-underline">
                                    Зарегистрироваться на курс</a>
                            }
                            {
                                item.courseLink !== '' &&
                                <a href={item.courseLink} target="_blank"
                                   className="px-3 cursor-pointer no-underline">
                                    Перейти на страницу курса</a>
                            }
                            {
                                (item.courseTestUse) &&
                                <NavLink to={`/lk/education/academy/test/${item.id}`}
                                className="px-3 no-underline">Пройти тест</NavLink>
                            }
                        </>
                }
            </>
        )
    }

    renderTest(item) {
        return (
            <>
                {   item.isPassed
                    ?
                        "Курс пройден"
                    :
                        item.courseTestUse ?
                            <NavLink to={`/lk/education/academy/test/${item.id}`}
                                     className="px-3 no-underline">Пройти тест</NavLink>
                            :
                            <a>{item.lockTestDays != 0 ? 'Дней до разблокировки: ' + item.lockTestDays : 'Тест пройден'}</a>
                }
            </>
        )
    }

    renderWebinar(item) {
        return (
            <>
                {
                    item.personalLink === ''
                        ?
                        <a target="_blank" className="cursor-pointer"
                           onClick={() => this.registerWebinar(item.id)}>
                            Зарегистрироваться на вебинар</a>
                        :
                        <a target="_blank" className="cursor-pointer"
                           href={item.personalLink}>
                            Перейти к вебинару</a>
                }
            </>
        )
    }

    renderRecord(item) {
        return (
            <>
                {
                    <NavLink to={`/lk/education/academy/video/${item.id}`}
                             className="px-3 no-underline">Перейти к вебинару</NavLink>
                }
            </>
        )
    }

    render() {
        const types = this.getTypes();

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs">
                    Essilor Академия
                    <a className="absolute ml-3" href="https://t.me/joinchat/WL9jBGvUTkUCH6yQ" target="_blank">
                        <img src="/assets/resources/telegram-icon.png" height="34px" width="34px"/>
                    </a>
                </h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                </ul>

                <div className="filters flex w-full flex-col sm:flex-row mb-4 items-start">
                    <div className="flex flex-col sm:flex-row flex-wrap sm:items-center  items-start mr-6 mt-2">
                        <div className="pr-4 ">Сортировка</div>
                        <Select
                            options={this.getSorts()}
                            defaultValue={this.getSorts()[0]}
                            className="w-64"
                            onChange={value => this.handleChangeSort(value)}
                        />
                    </div>
                    <div className="flex flex-col sm:flex-row flex-wrap sm:items-center  items-start mr-6 mt-2">
                        <div className="pr-4">Формат обучения</div>
                        <Select
                            options={types}
                            value={types.filter((item) => item.value === this.state.type.value)}
                            defaultValue={types.filter((item) => item.value === this.state.type.value)}
                            className="w-64"
                            onChange={value => this.handleChangeType(value)}
                        />
                    </div>
                </div>
                <div className="filters flex w-full flex-col sm:flex-row mb-4 items-start">
                    <div className="flex flex-col sm:flex-row flex-wrap sm:items-center  items-start mr-6 mt-2">
                        <div className="pr-8">Название</div>
                        <Select
                            options={this.state.titles}
                            value={this.state.titles.filter((item) => item.value === this.state.title.value)}
                            defaultValue={this.state.titles.filter((item) => item.value === this.state.title.value)}
                            className="w-64"
                            onChange={value => this.handleChangeTitle(value)}
                        />
                    </div>
                </div>

                <div className="items-result w-full academy">
                    {this.state.searching
                        ? <Spinner active={this.state.searching}/>
                        : this.state.items.map(item =>
                            (
                                <div className="items-result__item item-white box-shadow" key={`item-${item.id}`}>
                                    <img src={item.image}/>
                                    <div className="flex justify-between">
                                        <div className={`label ${item.isCourse ? `label__green` : (item.isTest ? `label__test` : `label__blue`)}`}>
                                            {item.type}
                                        </div>
                                        <div className="flex items-center item-white__time text-essilor-dark-blue">
                                            <span className="pr-3">
                                                {`${item.isCourse ? `Старт` : ``} ${item.date.d} ${item.date.m} ${item.date.y}`}
                                            </span>
                                            {
                                                item.isCourse ||
                                                <>
                                                    <i className="icon-clock"/>
                                                    <span className="pl-2 pr-3">{`${item.date.h}:${item.date.i}`}</span>
                                                </>
                                            }

                                        </div>
                                    </div>
                                    <div className="content">
                                        <h2 className="title">{item.name}</h2>
                                        <div className="description">
                                            {item.text}
                                        </div>
                                    </div>
                                    <div
                                        className="flex pt-4 text-center w-full justify-center bottom-content">
                                        {
                                            item.isCourse && this.renderCourse(item)
                                        }
                                        {
                                            item.isTest && this.renderTest(item)
                                        }
                                        {
                                            item.isWebinar && this.renderWebinar(item)
                                        }
                                        {
                                            item.isRecord && this.renderRecord(item)
                                        }
                                    </div>
                                </div>
                            )
                        )
                    }

                </div>

                <Modal key="courseModal" isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy uppercase">{this.state.modal.title}</h2>
                        <p className="text-center">{this.state.modal.text}</p>

                        <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                                onClick={() => this.closeModal()}>Закрыть</Button>
                    </div>
                </Modal>

                {
                    !this.state.webinarModalOpen ||
                    <Modal isOpen={this.state.webinarModalOpen}
                           hideCloseButton="true"
                           onClose={() => this.webinarModalClose()}
                           title="Регистрация на вебинар"
                    >

                        {   this.state.webinarModalStatus === 'needAdditionalFields'
                            ? this.renderAdditionalFields()
                            : this.renderMessageAfterRegistration()
                        }

                    </Modal>
                }
            </>

        );
    }
}

export default Page;
