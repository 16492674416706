import React from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';

import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import PageContestMarketing from './contest-detail-marketing';
import PageContestAcademy from './contest-detail-academy';
import PageContestSalesForce from './contest-detail-sf';
import PageContestOwner from './contest-detail-owner';
import PageContestEmployee from './contest-detail-employee';

import './contest.scss';

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/');
        this.id = path[3];
        const userGroups = localStorage.getItem('userGroups').split(',');
        this.detailContestPage = <p>Роль не определена</p>;

        if (userGroups.includes('8')) {
            this.detailContestPage = <PageContestMarketing {...this.props}/>
        } else if (userGroups.includes('9')) {
            this.detailContestPage = <PageContestAcademy {...this.props}/>
        } else if (userGroups.includes('7')) {
            this.detailContestPage = <PageContestSalesForce {...this.props}/>
        } else if (userGroups.includes('11') || userGroups.includes('12')) {
            this.detailContestPage = <PageContestOwner {...this.props}/>
        } else if (userGroups.includes('10') || userGroups.includes('14')) {
            this.detailContestPage = <PageContestEmployee {...this.props}/>
        }

        this.state = {
            access: 'processing'
        };
    }

    componentDidMount() {
        this.checkAccess();
    }


    checkAccess() {
        axios.get(
            `${apiHost}/contest/${this.id}/checkAccess`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({access: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    render() {
        const {access} = this.state;

        return (
            access === 'processing'
                ?
                <div className="w-full md:w-3/4 flex justify-center">
                    <object type="image/svg+xml" data="/assets/resources/Infinity-2s-200px.svg">
                        <img src="/assets/resources/Infinity-2s-200px.svg" alt="Поиск ..." />
                    </object>
                </div>
                : access === 'yes' ? this.detailContestPage : <p>У вас нет доступа</p>

        );
    }
}

export default Page;
