import React from "react";
import './profile-progress-line.scss';

const ProfileProgressLine = (props) => {
    let valueMax = props.valueMax || 1,
        value = props.value >= valueMax ? 100 : props.value * 100 / valueMax;

    return (
        <div className="profile-progress-line-wrapper">
            {props.title &&
                <div className="profile-progress-line-title title-semibold title-semibold__black">
                    {props.title}
                </div>
            }
            <div className="profile-progress-line">
                <div className={`profile-progress-line__progress ${props.color} ${value < 15 ? `small` : ``}`} style={{width: `${value}%`}}>
                    <span>{`${props.value} ${props.counting || 'баллов'}`}</span>
                </div>
            </div>
            <div className="profile-progress-line-total">
                {`${props.valueMax} ${props.counting || 'баллов'}`}
            </div>
        </div>

    )
};

export default ProfileProgressLine;
