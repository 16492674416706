import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from "react-redux";
import axios from "axios";
import ResizeDetector from "react-resize-detector";
import ReactPlayer from 'react-player';

import Layout from '../../../layouts/authorized';
import {apiHost} from "../../../common/config";
import store from "../../../store/store";

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            file: {
                src: '',
                type: ''
            },
            isRequestSend: false,
            played: 0
        };

        this.layout = React.createRef();

        this.elementId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
    }

    componentDidMount() {

        axios.get(
            `${apiHost}/file/academy-video/${this.elementId}`, {
                withCredentials: true
            }
        ).then(res => {
            this.setState({name: res.data.name, file: res.data.file});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        setTimeout(() => {this.addActivity()}, 3000);
    }

    checkLayoutSize() {
        this.setState({useSelectFilter: this.layout.current && this.layout.current.offsetWidth < 1050});
    }

    handleProgress = state => {
        const {isRequestSend} = this.state;

        if (!isRequestSend && state.played > 0.8) {
            this.saveEvent();
            this.setState({isRequestSend: true});
        }
    }



    renderViewer() {
        const filePath = this.state.file.src;

        return (
            <ReactPlayer
                width={'100%'}
                url={filePath}
                controls
                onProgress={this.handleProgress}/>
        )
    }

    render() {

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs">
                    Essilor Академия
                    <a className="absolute ml-3" href="https://t.me/joinchat/WL9jBGvUTkUCH6yQ" target="_blank">
                        <img src="/assets/resources/telegram-icon.png" height="34px" width="34px"/>
                    </a>
                </h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                    <li>></li>
                    <li><NavLink to="/lk/education/academy">Essilor Академия</NavLink></li>
                </ul>
                <h3 className="page-title page-title--withoutborder page-title--second">{this.state.name}</h3>

                {
                    this.state.file.type && this.renderViewer()
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        menuIsOpen: state.menu.menuIsOpen
    }
};

export default connect(mapStateToProps)(Page);
