import React, {Fragment} from 'react';

import PageContests from './contest-list';
import PageContest from './contest-details';

import Layout from "../../../layouts/authorized";

class Page extends React.Component {

    render() {
        const path = this.props.location.pathname.split('/');
        const currentPage = path[3];
        const page = currentPage === undefined ? <PageContests {...this.props}/> : <PageContest {...this.props}/>;

        return (<Layout>{page}</Layout>);
    }
}


export default Page;
