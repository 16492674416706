import React from 'react';
import Form from '../../components/forms/forms';
import {NavLink, Redirect} from 'react-router-dom';
import Icon from '../../icons/';
import Input from '../../components/UI/Input/Input';
import Select from '../../components/UI/Select/Select';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import axios from 'axios';
import {apiHost, host} from '../../common/config';
import queryString from 'query-string';
import Layout from '../../layouts/unauthorized';
import Button from "../../components/UI/Button/Button";
import _ from 'lodash';
import FeedbackModalExt from "../../components/feedback-modal/feedback-modal-ext";


class Page extends Form {

    validationRules = {
        // company: {require: true},
        address: {require: true, title: 'Адрес где, я работаю'},
        role: {require: true, title: 'Моя роль в системе'},
        name: {require: true, title: 'Ваше Имя'},
        secondName: {require: true, title: 'Ваше Отчество'},
        lastName: {require: true, title: 'Ваша Фамилия'},
        email: {require: true, title: 'Электронная почта', email: true},
        phone: {require: true, title: 'Контактный телефон', minLength: 11},
        imNotMedical: {require: true, title: 'Я не являюсь медицинским работником'},
        imPersonalAgree: {require: true, title: 'Согласие на обработку персональных данных'}
    }

    constructor(props) {
        super(props);

        const {hash, role, entity, owner} = queryString.parse(props.location.search);
        this.requestUrl = `${apiHost}/user/signup/by/link`;

        this.state = {
            errors: {},
            responseError: '',

            userByHash: {
                ROLE: '',
                ENTITY: '',
                EMAIL: '',
                ADDRESS: '',
                AVAILABLE_ADDRESSES: [],
                AVAILABLE_ROLES: []
            },
            hashInvalidMessage: '',
            hasSignedUp: false,
            params: {
                hash: hash,
                type: role,
                entity: entity,
                owner: owner

            }
        };
    }

    getAgreeLink = () => (
        <span>
            Согласен на предоставление и обработку персональных данных в соответствии с <a href={`${host}/files/agreement_12052020.pdf`} target="_blank">Политикой конфиденциальности и обработки персональных данных</a> ООО «Эссилор-ЛУЙС-Оптика» *
        </span>
    );

    submitRequestCalllback = (res) => {
        this.setState({hasSignedUp: true});
    }

    componentDidMount()
    {
        const {hash, role, entity, owner} = queryString.parse(this.props.location.search);
        if (_.includes(['owner'], role)) {
            this.validationRules.address.require = false;
        }


        if (hash && role && entity) {
            axios.get(
                `${apiHost}/user/get/by/link`,
                {
                    params: {
                        hash: hash,
                        role: role,
                        entity: entity,
                        owner: owner
                    }
                }
            ).then(res => {


                this.setState({userByHash: res.data});
            })
            .catch(error => {
                this.setState({hashInvalidMessage: error.response.data.error.message});
            });
        }
    }

    handleChangeAddress = (selectedOption, event) => {
        this.removeError(event.name);
        const userByHash = this.state.userByHash;
        userByHash.ADDRESS = selectedOption.value;

        this.setState({userByHash: userByHash });
    };

    handleChangeRole = (selectedOption, event) => {
        this.removeError(event.name);
        const userByHash = this.state.userByHash;
        userByHash.ROLE = selectedOption.value;

        if (userByHash.ROLE === 10) {
            this.validationRules.imNotMedical.require = false;
        } else {
            this.validationRules.imNotMedical.require = true;
        }

        this.setState({userByHash: userByHash });
    };


    render() {
        const {hash} = queryString.parse(this.props.location.search);

        return (
            <Layout>
            <div className="flex min-h-screen flex-wrap md:flex-no-wrap md:flex-row flex-col-reverse main-page">
                <div className="lg:w-2/5 md:w-1/2  p-6 flex flex-col mx-auto">
                    <div className="px-4 text-center flex flex-col justify-center items-center h-full">

                        <div className="text-center">
                            <h1 className="black">Регистрация</h1>
                        </div>

                        {
                            !hash ? this.renderTextWithoutHash() :
                                this.state.hashInvalidMessage ? this.renderHashInvalidMessage() :
                                    this.state.hasSignedUp ? this.renderSignedUpForm() :
                                        this.renderForm()
                        }

                    </div>
                </div>
                <div className="lg:w-3/5 md:w-1/2 background-main"/>
            </div>
            </Layout>
        );
    }

    renderForm() {
        const {hash} = queryString.parse(this.props.location.search);

        return (
            <>
            <div className="text-center">
                <p>Добро пожаловать! Проверьте пожалуйста правильность внесенных данных,
                    а также дозаполните поля для завершения регистрации</p>
            </div>

            <div className="text-center text-red-500 h-10 mt-6">{this.state.responseError}</div>

            <div className="w-4/5 text-left mx-auto">
                <form onSubmit={this.submitHandler.bind(this)} method="post">
                    <input type="hidden" name="hash" value={hash} />
                    <input type="hidden" name="type" value={this.state.params.type} />
                    <input type="hidden" name="entity" value={this.state.params.entity} />
                    <input type="hidden" name="owner" value={this.state.params.owner} />
                    <Input label="Мое юридическое лицо" disabled defaultValue={this.state.userByHash.ENTITY}/>

                    { !_.includes(['owner'], this.state.params.type) &&
                        <Select
                            name="address"
                            label="Адрес где, я работаю *"
                            placeholder="Выберите..."
                            errors={this.state.errors}
                            options={this.state.userByHash.AVAILABLE_ADDRESSES}
                            className="w-full"
                            onChange={this.handleChangeAddress}
                        />
                    }

                    <Select
                        name="role"
                        label="Моя роль в системе *"
                        placeholder="Выберите..."
                        errors={this.state.errors}
                        options={this.state.userByHash.AVAILABLE_ROLES}
                        className="w-full"
                        onChange={this.handleChangeRole}
                    />

                    <Input label="Ваше Имя *" placeholder="Введите Имя" name="name"
                           onChange={this.removeError}
                           errors={this.state.errors}/>

                    <Input label="Ваше Отчество *" placeholder="Введите Отчество" name="secondName"
                           onChange={this.removeError}
                           errors={this.state.errors}/>

                    <Input label="Ваша Фамилия *" placeholder="Введите Фамилию" name="lastName"
                           onChange={this.removeError}
                           errors={this.state.errors}/>

                    <Input label="Электронная почта *"
                           placeholder="Введите адрес электронной почты"
                           onChange={this.removeError}
                           name="email"
                           errors={this.state.errors}/>

                    <Input label="Контактный телефон *" placeholder="Номер телефона" mask="+7 (999) 999-99-99"
                           onChange={this.removeError}
                           name="phone"
                           errors={this.state.errors}/>
                    { this.state.userByHash.ROLE !== 10
                        ?
                            <Checkbox label="Я не являюсь медицинским работником *" name="imNotMedical"
                                  onChange={this.removeError}
                                  errors={this.state.errors}/>
                        : null
                    }

                    <Checkbox label={this.getAgreeLink()} name="imPersonalAgree"
                              onChange={this.removeError}
                              errors={this.state.errors}/>

                    <p className="text-left text-sm">* - обязательно для заполнения</p>
                    <Button type="submit" className="btn-blue uppercase my-8 block w-full">
                        Зарегистрироваться
                    </Button>
                </form>

                <p className="text-center opacity-50 hover:opacity-100">
                    <small>
                        <NavLink to="#">Это не мое Юридическое лицо</NavLink>
                    </small>
                </p>

                <p className="text-center my-6">
                    <small className="opacity-50 hover:opacity-100">
                        <NavLink to="/signin">Я уже зарегистрирован</NavLink>
                    </small>
                </p>

                <FeedbackModalExt/>

                <NavLink to="/">
                    <Icon name="logo" fill="#3A84D0" className="mx-auto max-w-8/9 mt-10"/>
                </NavLink>
            </div>
            </>
        )
    }

    renderTextWithoutHash() {
        return (
            <div className="w-4/5 text-left mx-auto text-center mt-6">
                <p>Добро пожаловать! Для регистрации в системе, пожалуйста обратитесь по адресу
                    &nbsp;<a href="mailto:support@essilor-pro.ru">support@essilor-pro.ru</a>
                </p>

                <p className="mt-10 mb-4">
                    <small className="opacity-50 hover:opacity-100">
                        <NavLink to="/signin">Войти в личный кабинет</NavLink>
                    </small>
                </p>

                <FeedbackModalExt/>

            </div>
        )
    }

    renderHashInvalidMessage() {
        return (
            <div className="w-4/5 text-left mx-auto text-center mt-6">
                <p className="text-red-500 mb-6">
                    {this.state.hashInvalidMessage}
                </p>
                <p>Если вы считаете, что произошла ошибка, пожалуйста обратитесь по адресу
                    &nbsp;<a href="mailto:info@essilor-pro.ru">info@essilor-pro.ru</a>
                </p>

                <p className="mt-10 mb-6">
                    <small className="opacity-50 hover:opacity-100">
                        <NavLink to="/signin">Войти в личный кабинет</NavLink>
                    </small>
                </p>

                <FeedbackModalExt/>
            </div>
        )
    }

    renderSignedUpForm() {
        return (
            <div className="w-4/5 text-left mx-auto text-center mt-6">
                <p>Спасибо за регистрацию в системе. На указанный в регистрационной информации
                    электронный адрес отправлено письмо с информацией для входа в систему.
                </p>

                <Button to="/signin" className="btn-blue uppercase my-10 block w-full">
                    Войти в личный кабинет
                </Button>

                <FeedbackModalExt/>
            </div>
        )
    }

}


export default Page;
