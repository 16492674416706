import React from 'react';
import '../sales-stat.scss';


class StatTableCellCurrency extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    getClassWrapper(one, two) {
        if (one <= 0 && two <= 0) {
            return '';
        }

        if (one <= 0) {
            return  'sales-stat-cell-wrapper-red';
        }

        if (two <= 0) {
            return  'sales-stat-cell-wrapper-green';
        }

        const result = (one/two - 1) * 100;

        if (result > 0) {
            return  'sales-stat-cell-wrapper-green';
        }

        if (result < 0) {
            return  'sales-stat-cell-wrapper-red';
        }

        return '';
    }

    calculateCount(one, two) {

        if (one <= 0 && two <= 0) {
            return '-';
        }

        if (one <= 0) {
            return `${this.formatter(-1 * 100)}%`;
        }

        if (two <= 0) {
            return `${this.formatter(100)}%`;
        }

        return `${this.formatter((one/two - 1) * 100)}%`;
    }

    formatter(count) {
        const formatter = new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return formatter.format(Math.round(count));
    }

    currencyFormatter(count) {
        const formatter = new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return formatter.format(Math.round(count));
    }

    render() {

        return (
            <td className='sales-stat-cell-wrapper'>
                <div className='sales-stat-cell-wrapper-tip'>
                    {`${this.props.title_one} - ${this.currencyFormatter(this.props.count_one)}`}
                    <br/>
                    {`${this.props.title_two} - ${this.currencyFormatter(this.props.count_two)}`}
                </div>
                <span className={this.getClassWrapper(this.props.count_one, this.props.count_two)}>
                    {this.calculateCount(this.props.count_one, this.props.count_two)}
                </span>
            </td>
        );
    }
}

export default StatTableCellCurrency;