import React from 'react';
import {NavLink} from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';

import {apiHost} from '../../../../common/config';
import store from '../../../../store/store';
import EntityTableComponent from './entity.table.component';
import OwnerEmployeesTable from './owner.employees.table.component';

import '../contest.scss';

class InviteForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            processing: true,
            data: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({processing: true});

        axios.get(
            `${apiHost}/contest/${this.props.contestId}/${this.props.type}`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({data: res.data, processing: false});
        });
    }

    invite = (event, url) => {
        event.preventDefault();
        this.setState({processing: true});

        const formData = new FormData(event.target);

        this.submitForm(formData, url);
    };

    submitForm(formData, url) {
        axios(`${apiHost}${url}`,
            {
                method: 'post',
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            store.dispatch({
                type: 'addResponseStatus',
                status: {
                    code: 200,
                    message: res.data.message
                }
            });
            this.fetchData();
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    render() {
        return (
            <>
                {
                    this.state.processing ?
                        <div className="w-full md:w-3/4 flex justify-center">
                            <object type="image/svg+xml" data="/assets/resources/Infinity-2s-200px.svg">
                                <img src="/assets/resources/Infinity-2s-200px.svg" alt="Поиск ..." />
                            </object>
                        </div>
                        :
                        <div className="flex flex-col lg:flex-row w-full mt-6 ">
                            <div className="w-full mb-6">
                                {this.props.type === 'entities' &&
                                <form onSubmit={event => this.invite(event, '/contest/entity/invite')}>
                                    <input name='contestId' value={this.props.contestId} type="hidden"/>
                                    <EntityTableComponent
                                        data={_.sortBy(this.state.data, ['name'])}
                                        allowInvite={this.props.allowInvite}
                                    />
                                </form>
                                }

                                {this.props.type === 'employees' &&
                                <form onSubmit={event => this.invite(event, `/contest/employee/invite`)}>
                                    <input name='contestId' value={this.props.contestId} type="hidden"/>
                                    <OwnerEmployeesTable
                                        data={_.sortBy(this.state.data, ['name'])}
                                        allowInvite={this.props.allowInvite}
                                    />
                                </form>
                                }
                            </div>
                        </div>
                }

            </>
        );
    }
}

export default InviteForm;
